import { selectAlertEntityOptions } from 'app/modules/alerts/selectors';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

// Components
import {
  U21Select,
  U21SelectOptionProps,
  U21Tooltip,
} from 'app/shared/u21-ui/components';

// Selectors
import { selectLinkAnalysisFilters } from 'app/modules/filtersOld/selectors';

// Actions
import { setLinkAnalysisFilters } from 'app/modules/filtersOld/actions';

// Models
import { LinkAnalysisFilters } from 'app/modules/filtersOld/models';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';

export const FILTER_TO_LABEL: Record<keyof LinkAnalysisFilters, string> = {
  // Selectable
  selectedEntity: 'Entity',
  groupBy: 'Grouping',
  eventTypeShown: 'Coloring',

  // Checkbox filters
  showCurrentAlert: 'Only show transactions in this alert',
  showEntities: 'Entities',
  showCurrency: 'Currency',
  showInstruments: 'Instruments',

  // Used for storing the legend filters
  eventTypesShown: '',
};

export const SelectFilter = ({
  filterKey,
  options,
}: {
  filterKey:
    | StringKey<LinkAnalysisFilters>
    | LinkAnalysisFilters['selectedEntity'];
  options: U21SelectOptionProps[];
}) => {
  const dispatch = useDispatch();

  const filters = useSelector(selectLinkAnalysisFilters);
  const value = filters[filterKey];
  const label = FILTER_TO_LABEL[filterKey];

  const handleFilterChange = (newValue: string) => {
    dispatch(
      setLinkAnalysisFilters({
        ...filters,
        [filterKey]: newValue,
      }),
    );
  };

  // If there are no entities associated with the given alert, hide the entity dropdown
  if (filterKey === 'selectedEntity' && options.length === 0) {
    return <div />;
  }

  /*
    If there is at least one associated entity and the 'Only show transactions in this alert'
    checkbox is checked, we exclude the entity ID from the payload and disable the dropdown
  */
  if (
    filterKey === 'selectedEntity' &&
    options.length > 0 &&
    filters.showCurrentAlert
  ) {
    const hardcodedEntity =
      options.length === 1
        ? options[0]
        : {
            text: 'All',
            value: 'all',
          };
    const disabledReason =
      options.length === 1
        ? 'This is disabled when only flagged events are shown'
        : 'You cannot filter by entity when viewing only flagged events';

    return (
      <U21Tooltip tooltip={disabledReason}>
        <StyledU21Select
          disabled
          options={[hardcodedEntity]}
          value={hardcodedEntity.value}
          onChange={emptyFn}
          label={label}
          clearable={false}
        />
      </U21Tooltip>
    );
  }

  return (
    <StyledU21Select
      clearable={false}
      searchable={false}
      options={options}
      value={value}
      onChange={handleFilterChange}
      label={label}
    />
  );
};

const StyledU21Select = styled(U21Select)`
  width: 250px;
`;

export const EntitySelect = () => {
  const entityOptions = useSelector(selectAlertEntityOptions);

  return <SelectFilter filterKey="selectedEntity" options={entityOptions} />;
};
