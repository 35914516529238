import { TxnMetadataPayload } from 'app/modules/fraudDashboard/requests';
import {
  FraudLossFormState,
  LossOverTimeGrouping,
} from 'app/modules/fraudDashboard/models';
import { selectFormatAmount } from 'app/modules/orgSettings/selectors';
import { DateRangeOption } from 'app/modules/fraudDashboard/constants';

export const updateFraudLoss = (
  row: TxnMetadataPayload,
  batchUpdates: FraudLossFormState['batchUpdates'],
): TxnMetadataPayload => {
  const newValues = { ...row };

  if (batchUpdates?.fraudType) {
    newValues.fraud_type = batchUpdates.fraudType;
  }
  if (batchUpdates?.fraudVictim) {
    newValues.fraud_victim = batchUpdates.fraudVictim;
  }

  newValues.actual_loss = newValues.potential_loss;
  newValues.recovered_loss = 0;
  newValues.prevented_loss = 0;

  return newValues;
};

export const getChartAmountsFormatter = (
  orgFormatAmount: ReturnType<typeof selectFormatAmount>,
) => {
  return (value: string | number): string => {
    const amount = Number(value);
    if (isNaN(amount)) {
      return `${value}`;
    }
    return orgFormatAmount({
      amount,
      shortenDecimalPlaces: true,
      precision: 0,
    });
  };
};

export const getDefaultFraudOverTimeGrouping = (
  opt: DateRangeOption,
): LossOverTimeGrouping => {
  switch (opt) {
    case 'Last week':
      return 'day';
    case 'Last month':
      return 'week';
    case 'Last 3 months':
      return 'week';
    case 'Last 6 months':
      return 'month';
    case 'Last 12 months':
      return 'month';
    default:
      return 'day';
  }
};
