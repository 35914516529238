import { IconSearch } from '@u21/tabler-icons';
import { AiAdminInfo } from 'app/modules/aiAdmin/models';
import { PaginatedAiAdminTrackingResponse } from 'app/modules/aiAdmin/responses';
import { AiAdminTypes } from 'app/modules/sidebar/models';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { selectAiAdminTrackingTableConfig } from 'app/shared/CustomConfig/selectors';

export const DEFAULT_GET_AI_ADMIN_TRACKING_RESPONSE: PaginatedAiAdminTrackingResponse =
  {
    count: null,
    tracking_info: [],
  };

export const AI_ADMIN_INFO_MAP: Record<AiAdminTypes, AiAdminInfo> = {
  [AiAdminTypes.AI_ADMIN_TRACKING]: {
    title: 'AI Investigation Tracking Info',
    icon: <IconSearch />,
    tableConfigType: TableConfigType.AI_ADMIN_TRACKING_TABLE,
    tableConfigSelector: selectAiAdminTrackingTableConfig,
  },
};
