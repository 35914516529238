import { useQuery } from '@tanstack/react-query';
import { NETWORK_ANALYSIS_SETTINGS_QUERY_KEY } from 'app/modules/networkAnalysisSettings/constants';
import { NetworkAnalysisType } from 'app/modules/networkAnalysisSettings/types';
import { selectHasReadCustomDataUserPermissions } from 'app/modules/session/selectors';
import { get } from 'app/shared/utils/fetchr';
import { useSelector } from 'react-redux';

export interface NetworkAnalysisConfigResponse {
  updated_at?: string; // if it is undefined it means the config is a default, and not saved to the DB
  type: NetworkAnalysisType;
  matchers: number[];
}

const getNetworkAnalysisSettings = () =>
  get<{ configs: NetworkAnalysisConfigResponse[] }>(
    '/network-analysis/settings',
  );

export const useGetNetworkAnalysisSettings = () => {
  const hasReadCustomDataSettingsPermission = useSelector(
    selectHasReadCustomDataUserPermissions,
  );
  return useQuery({
    queryKey: NETWORK_ANALYSIS_SETTINGS_QUERY_KEY,
    queryFn: () => getNetworkAnalysisSettings(),
    staleTime: Infinity,
    enabled: hasReadCustomDataSettingsPermission,
  });
};
