import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { FullAttachmentResponse } from 'app/modules/attachments/types';
import { ATTACHMENT_QUERY_KEYS } from 'app/modules/attachments/queries/keys';
import { GetAttachmentListResponse } from 'app/modules/attachments/types/responses';

export const useGetAttachment = (id?: number) => {
  return useQuery<FullAttachmentResponse | undefined>({
    queryKey: ATTACHMENT_QUERY_KEYS.getAttachment(id || 0),
    queryFn: async () => {
      const response = await post<GetAttachmentListResponse>(
        '/attachments/list',
        {
          attachment_ids: [id],
        },
      );

      return response.attachments[0];
    },
    enabled: !!id,
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};
