import { useQuery } from '@tanstack/react-query';

import { post } from 'app/shared/utils/fetchr';
import { TXN_ANALYSIS_QUERY_KEYS } from 'app/modules/txnAnalysis/queries/keys';
import { TxnAnalysisPayload } from 'app/modules/txnAnalysis/requests';
import { TxnStatsResponse } from 'app/modules/transactions/types';
import { useSelector } from 'react-redux';
import {
  selectDLEnabledForAlertTxnAnalysis,
  selectDLEnabledForCaseTxnAnalysis,
} from 'app/shared/featureFlags/selectors';
import { TxnAnalysisType } from 'app/modules/txnAnalysis/constants';

export interface TxnStatsFormattedResponse {
  txnAnalysisTotalAmount: number;
  txnAnalysisTotalSentData: { name: string; value: number }[];
  txnAnalysisTotalReceivedData: { name: string; value: number }[];
}

export const retrieveTxnAnalysisStats = (
  payload: TxnAnalysisPayload,
): Promise<TxnStatsFormattedResponse> => {
  return post(`/txn-analysis/stats`, payload);
};

export const retrieveTxnAnalysisStatsFromCache = (
  payload: TxnAnalysisPayload,
): Promise<TxnStatsResponse> => {
  return post(`/txn-analysis/paginated-stats`, payload);
};

const isFormattedRes = (
  res: TxnStatsFormattedResponse | TxnStatsResponse,
): res is TxnStatsFormattedResponse => 'txnAnalysisTotalAmount' in res;

export const useGetTxnAnalysisStats = (payload: TxnAnalysisPayload) => {
  // For now we only support the cache endpoint for alert txn analysis
  const useCacheEndpointForAlert =
    useSelector(selectDLEnabledForAlertTxnAnalysis) &&
    payload.type === TxnAnalysisType.ALERT;
  const useCacheEndpointForCase =
    useSelector(selectDLEnabledForCaseTxnAnalysis) &&
    payload.type === TxnAnalysisType.CASE;
  const useCacheEndpoint = useCacheEndpointForAlert || useCacheEndpointForCase;

  return useQuery({
    queryKey: TXN_ANALYSIS_QUERY_KEYS.getTxnAnalysisStatsQueryKey(
      payload,
      useCacheEndpoint,
    ),
    queryFn: (): Promise<TxnStatsFormattedResponse | TxnStatsResponse> =>
      useCacheEndpoint
        ? retrieveTxnAnalysisStatsFromCache(payload)
        : retrieveTxnAnalysisStats(payload),
    meta: { errorMessage: 'Failed to get transaction analysis statistics.' },
    select: (data): TxnStatsFormattedResponse => {
      if (isFormattedRes(data)) {
        return data;
      }
      return {
        txnAnalysisTotalAmount: data.txn_analysis_total_amount,
        txnAnalysisTotalSentData: data.txn_analysis_total_sent_data,
        txnAnalysisTotalReceivedData: data.txn_analysis_total_received_data,
      };
    },
  });
};
