import { useMemo, useState } from 'react';

// Components
import { IconArrowRight, IconFlag } from '@u21/tabler-icons';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';
import { InstrumentsDocumentChipList } from 'app/modules/instruments/components/InstrumentsDocumentChipList';

// Models
import { TxnInstrumentSidebarDefinition } from 'app/modules/sidebar/models';

// Utils
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { SidebarSummary } from 'app/modules/sidebar/components/utils/SidebarSummary';
import { SummaryViewConfigTypeClassifier } from 'app/modules/summarySettings/types';
import {
  U21Button,
  U21Spacer,
  U21Subsection,
  U21TitleCountLabel,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { SidebarCommentsCollapsible } from 'app/modules/sidebar/components/SidebarCommentsCollapsible';
import { SidebarDataLabels } from 'app/modules/sidebar/components/SidebarDataLabels';
import { useInstrumentDetails } from 'app/modules/instruments/queries/useInstrumentDetails';
import { SidebarExternalLinkList } from 'app/modules/sidebar/components/SidebarExternalLinkList';
import { sortByTime } from 'app/modules/dataExplorer/helpers';
import { formatDate } from 'app/shared/utils/date';
import styled from 'styled-components';
import { useInstrumentAlerts } from 'app/modules/instruments/queries/useInstrumentAlerts';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';
import { useSelector } from 'react-redux';

interface OwnProps {
  data: TxnInstrumentSidebarDefinition['data'];
}

export const SidebarInstrument = ({ data }: OwnProps) => {
  const [showEmptyFields, setShowEmptyFields] = useState(false);

  const {
    data: instrument,
    isLoading,
    refetch,
  } = useInstrumentDetails(String(data.id));

  const tagIds = useMemo(
    () => instrument?.tags.map((i) => i.id) || [],
    [instrument?.tags],
  );

  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const { data: instrumentAlerts } = useInstrumentAlerts(
    instrument?.external_id || '',
    {
      limit: 100,
      offset: 1,
    },
    hasReadAlertsPermission,
  );

  const idForButtonLink = data.id || instrument?.id;
  return (
    <U21SideMenu
      actionButtonProps={{
        children: 'Go to Detail Page',
        endIcon: <IconArrowRight />,
        disabled: !idForButtonLink,
        to: ROUTES_MAP.instrumentsId.path.replace(
          ':id',
          String(idForButtonLink),
        ),
      }}
      footerLeftContent={
        <U21Button
          onClick={() => setShowEmptyFields((prev) => !prev)}
          variant="ghost"
        >
          {showEmptyFields ? 'Hide' : 'Show'} Empty Fields
        </U21Button>
      }
      loading={isLoading}
      noPadding
      title="Instrument Details"
    >
      <SidebarSummary
        classifier={SummaryViewConfigTypeClassifier.INSTRUMENT}
        details={instrument}
        showEmptyFields={showEmptyFields}
      />
      <U21Spacer spacing={0.5}>
        <U21Subsection
          title={
            <U21TitleCountLabel
              count={instrument?.documents.length || 0}
              label=""
            >
              Documents
            </U21TitleCountLabel>
          }
          collapsible
          collapsed
          shaded
        >
          {instrument && instrument.documents.length > 0 ? (
            <InstrumentsDocumentChipList
              documents={instrument.documents}
              handleOnDelete={() => refetch()}
            />
          ) : (
            <U21Typography variant="caption" color="text.secondary">
              No documents found
            </U21Typography>
          )}
        </U21Subsection>
        {hasReadAlertsPermission && (
          <U21Subsection
            title={
              <U21TitleCountLabel count={instrumentAlerts?.count || 0} label="">
                Flagged Alerts
              </U21TitleCountLabel>
            }
            titleIcon={<IconFlag />}
            collapsible
            shaded
          >
            {instrumentAlerts && instrumentAlerts.count > 0 ? (
              <SidebarExternalLinkList
                list={sortByTime(instrumentAlerts.alerts, 'start_date').map(
                  (i) => ({
                    id: i.id,
                    label: formatDate(i.start_date),
                    route: ROUTES_MAP.alertsId.path.replace(
                      ':id',
                      String(i.id),
                    ),
                    value: i.title,
                  }),
                )}
              />
            ) : (
              <StyledU21Typography variant="caption" color="text.secondary">
                No flagged alerts
              </StyledU21Typography>
            )}
          </U21Subsection>
        )}
        <SidebarDataLabels ids={tagIds} />
        <SidebarCommentsCollapsible comments={instrument?.comments} />
      </U21Spacer>
    </U21SideMenu>
  );
};

const StyledU21Typography = styled(U21Typography)`
  margin-bottom: 8px;
`;
