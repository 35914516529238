import { SearchRequest, SearchTypes } from 'app/modules/search/models';
import { GenericSearchPayload } from 'app/modules/search/payload';

const BASE = ['navigator'];

export const NAVIGATOR_QUERY_KEYS = {
  getVersion: () => [...BASE, 'version'],
  search: (
    type: SearchTypes | undefined,
    payload?: SearchRequest | GenericSearchPayload,
  ) => {
    if (payload) {
      return [...BASE, 'search', type, payload];
    }
    return [...BASE, 'search', type];
  },
};
