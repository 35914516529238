import { ChainalysisContentField } from 'app/modules/alerts/types';
import { TableConfig } from 'app/shared/pagination/models';

const CHAINALYSIS_ALERT_DETAILS_LABELS: Record<
  ChainalysisContentField,
  string
> = {
  [ChainalysisContentField.ALERT_IDENTIFIER]: 'Alert ID',
  [ChainalysisContentField.ASSET]: 'Asset',
  [ChainalysisContentField.LEVEL]: 'Level',
  [ChainalysisContentField.USER_ID]: 'User ID',
  [ChainalysisContentField.SERVICE]: 'Service',
  [ChainalysisContentField.CATEGORY]: 'Category',
  [ChainalysisContentField.CREATED_AT]: 'Created at',
  [ChainalysisContentField.DIRECTION]: 'Direction',
  [ChainalysisContentField.TIMESTAMP]: 'Event time',
  [ChainalysisContentField.ALERT_STATUS]: 'Alert status',
  [ChainalysisContentField.EXPOSURE_TYPE]: 'Exposure type',
  [ChainalysisContentField.MIN_THRESHOLD]: 'Rule threshold min',
  [ChainalysisContentField.MAX_THRESHOLD]: 'Rule threshold max',
  [ChainalysisContentField.TRANSACTION_HASH]: 'Transaction hash',
  [ChainalysisContentField.TRANSACTION_INDEX]: 'Transaction index',
  [ChainalysisContentField.TRANSFER_REFERENCE]: 'Transfer reference',
  [ChainalysisContentField.TRANSFER_REPORTED_AT]: 'Transfer reported at',
  [ChainalysisContentField.TRANSFER_OUTPUT_ADDRESS]: 'Transfer output address',
  [ChainalysisContentField.KYT_ALERT_URL]: 'KYT Alert URL',
  [ChainalysisContentField.ALERT_AMOUNT_USD]: 'Alert amount USD',
  [ChainalysisContentField.ALERT_STATUS_CREATED_AT]: 'Alert status created at',
  [ChainalysisContentField.ALERT_STATUS_CREATED_BY]: 'Alert status created by',
  [ChainalysisContentField.RULE]: 'Rule',
  [ChainalysisContentField.TRANSFERRED_VALUE_PERCENTAGE]:
    'Transferred value percentage',
};

export const CHAINALYSIS_ALERT_DETAILS_TABLE_CONFIG: TableConfig[] =
  Object.values(ChainalysisContentField).map((key) => ({
    key,
    label: CHAINALYSIS_ALERT_DETAILS_LABELS[key],
  }));

export const CHAINALYSIS_DATETIME_KEYS = [
  ChainalysisContentField.CREATED_AT,
  ChainalysisContentField.TRANSFER_REPORTED_AT,
  ChainalysisContentField.ALERT_STATUS_CREATED_AT,
  ChainalysisContentField.TIMESTAMP,
];

export const CHAINALYSIS_CHIP_KEYS = [
  ChainalysisContentField.ASSET,
  ChainalysisContentField.CATEGORY,
  ChainalysisContentField.LEVEL,
  ChainalysisContentField.DIRECTION,
  ChainalysisContentField.EXPOSURE_TYPE,
];
