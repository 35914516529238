// Components
import {
  U21Alert,
  U21Button,
  U21ExternalLinkModal,
  U21Loading,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import {
  IconEye,
  IconEyeOff,
  IconSparkles,
  IconThumbDown,
  IconThumbUp,
} from '@u21/tabler-icons';

// Queries
import { useAiInvestigationResults } from 'app/modules/investigations/queries/useAiInvestigationResults';
import { useTriggerAiInvestigation } from 'app/modules/investigations/queries/useTriggerAiInvestigation';

// Models
import { InvestigationType } from 'app/modules/investigations/models';

// Styles
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { useSelector } from 'react-redux';
import { selectHasEditWebhooksPermissions } from 'app/modules/session/selectors';
import { SURVEY, triggerInterceptSurvey } from 'app/shared/utils/intercom';
import { useLocalStorage } from 'app/shared/hooks/useLocalStorage';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { useCallback, useMemo, useState } from 'react';
import { useCreateNarrative } from 'app/modules/narratives/queries/useCreateNarrative';
import { AiInvestigationParagraph } from 'app/modules/investigations/components/AiInvestigation/AiInvestigationParagraph';
import { computeAiInvestigationSources } from 'app/modules/investigations/components/AiInvestigation/helpers';
import { AiInvestigationSources } from 'app/modules/investigations/components/AiInvestigation/AiInvestigationSources';

interface OwnProps {
  id: number;
  customChecklistSubmissionId?: number;
  isHistorical?: boolean;
}

export const AiInvestigationResults = ({
  id,
  customChecklistSubmissionId,
  isHistorical = false,
}: OwnProps) => {
  const history = useHistory();
  const hasEditWebhooksPermissions = useSelector(
    selectHasEditWebhooksPermissions,
  );

  const [aiFeedbacks, setAiFeedbacks] = useLocalStorage<
    { id: number; isHelpful: boolean }[]
  >(LocalStorageKeys.AI_INVESTIGATION_FEEDBACK, []);

  const [aiFeedbackGiven, setAiFeedbackGiven] = useState<
    | {
        id: number;
        isHelpful: boolean;
      }
    | undefined
  >(aiFeedbacks.find((e) => e.id === id));

  const [isSourcesShown, setIsSourcesShown] = useState<boolean>(true);
  const [isSourcesHovered, setIsSourcesHovered] = useState<boolean>(false);
  const [currentSources, setCurrentSources] = useState<number[]>([]);

  const { isLoading, data: aiInvestigationResults } = useAiInvestigationResults(
    InvestigationType.ALERT,
    {
      articleId: id,
      customChecklistSubmissionId,
    },
  );

  const { isPending, mutate } = useTriggerAiInvestigation({ article_id: id });

  const { isPending: createNarrativePending, mutateAsync: createNarrative } =
    useCreateNarrative(id);

  const handleFeedbackClick = useCallback(
    (isHelpful: boolean) => {
      const result = { id, isHelpful };
      setAiFeedbackGiven(result);

      // We are doing the following to store the latest 100 alerts that users give feedback on
      // (i.e. queue with limit of 100)
      // We need to first remove the previous entry before adding a new entry at index 0
      const newIsAiFeedbackGiven = [...aiFeedbacks];
      const prevIdx = newIsAiFeedbackGiven.findIndex(
        (feedback) => feedback.id === id,
      );

      if (prevIdx >= 0) {
        newIsAiFeedbackGiven.splice(prevIdx, 1);
      }

      newIsAiFeedbackGiven.unshift(result);
      setAiFeedbacks(newIsAiFeedbackGiven.slice(0, 100));
    },
    [id, aiFeedbacks, setAiFeedbacks],
  );

  // Memoized computation of sources since its a rather expensive operation
  const {
    hasSources,
    sourceNumberToExternalSourceMap,
    sourceNumberToInternalSourceMap,
    paragraphWithSource,
  } = useMemo(() => {
    if (
      aiInvestigationResults &&
      aiInvestigationResults.current_ai_investigations_results?.status ===
        'COMPLETED' &&
      !isEmpty(
        aiInvestigationResults.current_ai_investigations_results?.results,
      )
    ) {
      const {
        current_ai_investigations_results: { results },
      } = aiInvestigationResults;

      return {
        hasSources: results.references && results.references.length > 0,
        ...computeAiInvestigationSources(results),
      };
    }

    return {
      hasSources: false,
      sourceNumberToExternalSourceMap: new Map(),
      sourceNumberToInternalSourceMap: new Map(),
      paragraphWithSource: [],
    };
  }, [aiInvestigationResults]);

  if (
    aiInvestigationResults &&
    aiInvestigationResults.current_ai_investigations_results?.status ===
      'PENDING'
  ) {
    return <U21Loading loading label="AI Agent is computing..." />;
  }

  if (isLoading) {
    return <U21Loading loading />;
  }

  if (!aiInvestigationResults) {
    return undefined;
  }

  if (
    aiInvestigationResults.current_ai_investigations_results?.status === 'ERROR'
  ) {
    const errorMsg =
      aiInvestigationResults.current_ai_investigations_results.status_message;
    const isOpenAIError = errorMsg.includes('Error 500');

    return (
      <StyledSpacer spacing={2}>
        <StyledAlert
          title="Oops... Something went wrong!"
          severity={isOpenAIError ? 'error' : 'warning'}
        >
          {isOpenAIError ? (
            <>
              There seems to be an issue with the AI Agent. It might be that
              OpenAI is down -{' '}
              <U21ExternalLinkModal url="https://status.openai.com/">
                {({ onClick }) => (
                  <U21Button
                    onClick={(event) => {
                      onClick();
                      event.stopPropagation();
                    }}
                    variant="text"
                  >
                    <u>check the status page</u>
                  </U21Button>
                )}
              </U21ExternalLinkModal>{' '}
              and if it’s down, try again later. It might also be that one of
              the checklist items is causing the agent to fail.{' '}
              {hasEditWebhooksPermissions ? (
                <U21Button
                  onClick={() =>
                    history.push(ROUTES_MAP.alertInvestigationChecklists.path)
                  }
                  variant="text"
                >
                  <u>Create a new checklist</u>
                </U21Button>
              ) : (
                'Create a new checklist'
              )}{' '}
              and try again.
            </>
          ) : (
            errorMsg
          )}
        </StyledAlert>
        <U21Button
          startIcon={<IconSparkles />}
          variant="contained"
          color="primary"
          loading={isPending}
          onClick={() => {
            mutate();
          }}
        >
          Try Again
        </U21Button>
      </StyledSpacer>
    );
  }

  if (
    aiInvestigationResults.current_ai_investigations_results?.status ===
      'COMPLETED' &&
    !isEmpty(aiInvestigationResults.current_ai_investigations_results?.results)
  ) {
    const { narrative } = aiInvestigationResults;

    return (
      <U21Alert
        title={
          <U21Spacer spacing={0.2}>
            <U21Typography variant="subtitle2">AI Agent Findings</U21Typography>
            <AiInvestigationParagraph
              paragraphWithSource={paragraphWithSource}
              sourceNumberToExternalSourceMap={sourceNumberToExternalSourceMap}
              sourceNumberToInternalSourceMap={sourceNumberToInternalSourceMap}
              isSourcesHovered={isSourcesHovered}
              currentSources={currentSources}
              setCurrentSources={setCurrentSources}
            />
          </U21Spacer>
        }
        Icon={IconSparkles}
        severity="normal"
        collapsible
      >
        {narrative && (
          <StyledFooter horizontal>
            {hasSources ? (
              <U21Button
                color="primary"
                size="small"
                startIcon={isSourcesShown ? <IconEyeOff /> : <IconEye />}
                onClick={() =>
                  setIsSourcesShown((prevIsSourcesShown) => !prevIsSourcesShown)
                }
              >
                {isSourcesShown ? 'Hide ' : 'Show '} sources
              </U21Button>
            ) : (
              <div />
            )}

            <U21Spacer horizontal>
              <U21Button
                color="inherit"
                size="small"
                variant={
                  aiFeedbackGiven !== undefined && aiFeedbackGiven.isHelpful
                    ? 'contained'
                    : 'ghost'
                }
                aria-label="ai-investigation-result-helpful"
                startIcon={<IconThumbUp />}
                onClick={() => handleFeedbackClick(true)}
              >
                Helpful
              </U21Button>

              <U21Button
                color="inherit"
                size="small"
                variant={
                  aiFeedbackGiven !== undefined && !aiFeedbackGiven.isHelpful
                    ? 'contained'
                    : 'ghost'
                }
                aria-label="ai-investigation-result-not-helpful"
                startIcon={<IconThumbDown />}
                onClick={() => {
                  handleFeedbackClick(false);
                  triggerInterceptSurvey(
                    SURVEY.AI_INVESTIGATION_RESPONSE,
                    true,
                  );
                }}
              >
                Not helpful
              </U21Button>

              {!isHistorical && (
                <U21Button
                  color="primary"
                  size="small"
                  loading={createNarrativePending}
                  onClick={async () => {
                    try {
                      await createNarrative({ narrative });
                      history.push(
                        ROUTES_MAP.alertsIdTab.path
                          .replace(':id', id.toString())
                          .replace(':tab?', 'narrative'),
                      );
                    } catch {}
                  }}
                >
                  Add to narrative
                </U21Button>
              )}
            </U21Spacer>
          </StyledFooter>
        )}
        {hasSources && isSourcesShown && (
          <AiInvestigationSources
            sourceNumberToExternalSourceMap={sourceNumberToExternalSourceMap}
            sourceNumberToInternalSourceMap={sourceNumberToInternalSourceMap}
            currentSources={currentSources}
            setCurrentSources={setCurrentSources}
            setIsSourcesHovered={setIsSourcesHovered}
          />
        )}
      </U21Alert>
    );
  }

  return <U21Loading loading />; // NOTE: status is 'PENDING'
};

const StyledSpacer = styled(U21Spacer)`
  align-items: center;
`;

const StyledAlert = styled(U21Alert)`
  width: 100%;
`;

const StyledFooter = styled(U21Spacer)`
  margin-top: 16px;
  justify-content: space-between;
`;
