import { FilterOption } from 'app/modules/filters/models';

import { FILTER_TYPE } from 'app/modules/filters/constants';

// We are only filtering with number and string for now
interface PaginatedAiAdminTrackingFilters {
  id?: number;
  org_id?: number;
  ai_investigations_result_id?: number;
  created_at?: string[];
  updated_at?: string[];
  group_id?: string;
  framework?: string;
  model_name?: string;
  query_type?: string;
  tool_name?: string;
  prompt_version?: string;
  prompt_name?: string;
  prompt_template?: string;
  raw_prompt?: string;
  raw_response?: string;
  processed_response?: string;
  tokens_used?: number;
  input_tokens?: number;
  output_tokens?: number;
  cost?: number;
  latency_ms?: number;
  status?: string;
  error_message?: string;
  quality_score?: number;
  feedback?: string;
}

export const AI_ADMIN_TRACKING_FILTER_KEYS: Readonly<
  Record<
    Uppercase<keyof PaginatedAiAdminTrackingFilters>,
    keyof PaginatedAiAdminTrackingFilters
  >
> = {
  ID: 'id',
  ORG_ID: 'org_id',
  AI_INVESTIGATIONS_RESULT_ID: 'ai_investigations_result_id',
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at',
  GROUP_ID: 'group_id',
  FRAMEWORK: 'framework',
  MODEL_NAME: 'model_name',
  QUERY_TYPE: 'query_type',
  TOOL_NAME: 'tool_name',
  PROMPT_VERSION: 'prompt_version',
  PROMPT_NAME: 'prompt_name',
  PROMPT_TEMPLATE: 'prompt_template',
  RAW_PROMPT: 'raw_prompt',
  RAW_RESPONSE: 'raw_response',
  PROCESSED_RESPONSE: 'processed_response',
  TOKENS_USED: 'tokens_used',
  INPUT_TOKENS: 'input_tokens',
  OUTPUT_TOKENS: 'output_tokens',
  COST: 'cost',
  LATENCY_MS: 'latency_ms',
  STATUS: 'status',
  ERROR_MESSAGE: 'error_message',
  QUALITY_SCORE: 'quality_score',
  FEEDBACK: 'feedback',
} as const;

export const AI_ADMIN_TRACKING_FILTER_KEYS_VALUES: Set<string> = new Set(
  Object.values(AI_ADMIN_TRACKING_FILTER_KEYS),
);

export const ALL_AI_ADMIN_TRACKING_FILTER_OPTIONS: FilterOption[] = [
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.ID,
    label: 'ID',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.ORG_ID,
    label: 'Org ID',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.AI_INVESTIGATIONS_RESULT_ID,
    label: 'AI investigations result ID',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.CREATED_AT,
    label: 'Created at',
    type: FILTER_TYPE.DATE_RANGE,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.UPDATED_AT,
    label: 'Updated at',
    type: FILTER_TYPE.DATE_RANGE,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.GROUP_ID,
    label: 'Group ID',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.FRAMEWORK,
    label: 'Framework',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.MODEL_NAME,
    label: 'Model name',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.QUERY_TYPE,
    label: 'Query type',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.TOOL_NAME,
    label: 'Tool name',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.PROMPT_VERSION,
    label: 'Prompt version',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.PROMPT_NAME,
    label: 'Prompt name',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.PROMPT_TEMPLATE,
    label: 'Prompt template',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.RAW_PROMPT,
    label: 'Raw prompt',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.RAW_RESPONSE,
    label: 'Raw response',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.PROCESSED_RESPONSE,
    label: 'Processed response',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.TOKENS_USED,
    label: 'Tokens used',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.INPUT_TOKENS,
    label: 'Input tokens',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.OUTPUT_TOKENS,
    label: 'Output tokens',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.COST,
    label: 'Cost',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.LATENCY_MS,
    label: 'Latency (ms)',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.STATUS,
    label: 'Status',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.ERROR_MESSAGE,
    label: 'Error message',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.QUALITY_SCORE,
    label: 'Quality score',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AI_ADMIN_TRACKING_FILTER_KEYS.FEEDBACK,
    label: 'Feedback',
    type: FILTER_TYPE.TEXT,
  },
];
