import {
  RiskParameterName,
  RiskMetricName,
  RiskParameterArgs,
} from 'app/modules/riskRatings/components/riskParameters';

export enum RiskModelStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export enum RiskModelExecutionStatus {
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

export enum RiskModelExecutionType {
  ON_DEMAND = 'ON_DEMAND',
  SCHEDULED = 'SCHEDULED',
  VALIDATION = 'VALIDATION',
}

export enum RiskMetricType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  UNKNWON = 'UNKNWON',
}

export enum MetricMappingMethod {
  // Text
  STRING_MATCH = 'STRING_MATCH',
  STRING_LIST_MATCH = 'STRING_LIST_MATCH',
  MATCHLIST = 'MATCHLIST',

  // Number
  NUMERIC_COMPARISON = 'NUMERIC_COMPARISON',
  SCALE_NUMBER = 'SCALE_NUMBER',
}

export enum NumericComparisonOperator {
  GT = 'GT',
  LT = 'LT',
  EQ = 'EQ',
  NEQ = 'NEQ',
  IN_RANGE = 'IN_RANGE',
}

export interface BaseMetricMapping {
  id?: number;
  method: MetricMappingMethod;
  content: Record<string, any>;
  risk: number;
  matchlist_id?: number | null;
  default_high?: boolean;
}

interface MatchlistMetricMapping extends BaseMetricMapping {
  method: MetricMappingMethod.MATCHLIST;
  content: Record<string, never>;
  matchlist_id: number | null;
}

interface StringMatchMetricMapping extends BaseMetricMapping {
  method: MetricMappingMethod.STRING_MATCH;
  content: {
    value: string;
  };
}

interface StringListMatchMetricMapping extends BaseMetricMapping {
  method: MetricMappingMethod.STRING_LIST_MATCH;
  content: {
    value: string[];
  };
}

interface NumericComparisonMapping extends BaseMetricMapping {
  method: MetricMappingMethod.NUMERIC_COMPARISON;
  content:
    | {
        operator: Exclude<
          NumericComparisonOperator,
          NumericComparisonOperator.IN_RANGE
        >;
        value: number;
      }
    | {
        operator: NumericComparisonOperator.IN_RANGE;
        start: number | null;
        end: number | null;
      };
}

interface NumericScaleMapping extends BaseMetricMapping {
  method: MetricMappingMethod.SCALE_NUMBER;
  content: {
    min_value: number;
    max_value: number;
    min_risk: number;
  };
}

export type MetricMapping =
  | MatchlistMetricMapping
  | StringMatchMetricMapping
  | StringListMatchMetricMapping
  | NumericComparisonMapping
  | NumericScaleMapping;

// TODO improve typing, custom_data_setting_id is required if the metric_type is custom data
export interface ParameterMetric {
  id?: number;
  metric: RiskMetricName;
  metric_type: RiskMetricType;
  unscored_risk: number;
  unknown_risk: number;
  custom_data_setting_id?: number;
  risk_mappings: MetricMapping[];
}

export interface RiskParameter {
  id?: number;
  parameter: RiskParameterName;
  weight: number;
  parameter_args: RiskParameterArgs;
  metrics: ParameterMetric[];
}

type RiskSegmentFilters = Record<string, any>; // TODO improve typing

export interface EntityRiskSegment {
  title: string;
  filters: RiskSegmentFilters;
  parameters: RiskParameter[];
  id?: number;
}

export interface RiskMetricDetails {
  label: string;
  metricType: RiskMetricType;
  formatValue?: (value: number | string | null) => string;
}

export interface EntityRiskScoreSnapshotEntry {
  weight: number;
  parameter: RiskParameterName;
  parameter_trace: {
    parameter_id?: number;
    parameter_risk_score: number;
    risk_metric: RiskMetricName | null;
    risk_metric_tracking_id: number | null;
    risk_metric_trace: {
      object_ids: Record<string, string>;
      raw_value: string | number | null;
      risk_score: number;
      risk_mapping_trace?: MetricMapping;
    };
  };
}

export interface EntityRiskScoreSnapshot {
  segment_filters: RiskSegmentFilters;
  segment_id?: number;
  trace: EntityRiskScoreSnapshotEntry[];
}

export interface RiskMetricPayload {
  path: string;
  metric: ParameterMetric;
  riskParameterName: RiskParameterName;
}

/* 
  Array of 101 elements, each element represents the number of entities in the risk bucket 
    e.g risk_buckets[52] = 21; // Means that there is 21 entities with a risk score of 52
*/
export type AggregatedRiskBuckets = FixedLengthArray<number, 101>;

export interface RiskModelExecution {
  id: number;
  execution_type: RiskModelExecutionType;
  updated_at: string;
  start_time: string;
  end_time: string | null;
  status: RiskModelExecutionStatus;
  statistics: {
    processed_entity_count?: number;
    entity_count?: number;
    risk_buckets?: AggregatedRiskBuckets;
  };
}
