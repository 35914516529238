import { useMutation } from '@tanstack/react-query';
import { GetEntitiesPayload } from 'app/modules/entitiesRefresh/requests';
import { GetEntitiesResponse } from 'app/modules/entitiesRefresh/responses';
import { SearchTypes } from 'app/modules/search/models';
import { GetTransactionsPayload } from 'app/modules/transactions/requests';
import { GetTransactionsResponse } from 'app/modules/transactions/responses';
import {
  selectDLEnabledForEntityList,
  selectDLEnabledForTxnEventList,
} from 'app/shared/featureFlags/selectors';
import { DEFAULT_OFFSET } from 'app/shared/pagination/constants';
import { post } from 'app/shared/utils/fetchr';
import { useSelector } from 'react-redux';

export const useGetUnit21IdFromExternalId = (type?: SearchTypes) => {
  const useNewEntityEndpoint = useSelector(selectDLEnabledForEntityList);
  const useNewTransactionEndpoint = useSelector(selectDLEnabledForTxnEventList);

  const entityEndpoint = useNewEntityEndpoint
    ? '/entities/cached/list'
    : '/entities/list';

  const transactionEndpoint = useNewTransactionEndpoint
    ? '/txn-events/cached/list'
    : '/txn-events/list';

  return useMutation({
    mutationFn: async (externalIds: string[]) => {
      switch (type) {
        case SearchTypes.ENTITIES:
          // Transform the data here before returning it
          return (
            await post<GetEntitiesResponse>(entityEndpoint, {
              offset: DEFAULT_OFFSET,
              limit: externalIds.length,
              entity_external_ids: externalIds,
            } satisfies GetEntitiesPayload)
          ).entities.reduce(
            (acc, i) => ({ ...acc, [i.external_id]: i.id }),
            {} as Record<string, number>,
          );
        case SearchTypes.TRANSACTIONS:
          return (
            await post<GetTransactionsResponse>(transactionEndpoint, {
              offset: DEFAULT_OFFSET,
              limit: externalIds.length,
              txn_event_ids: externalIds,
            } satisfies GetTransactionsPayload)
          ).txns.reduce(
            (acc, i) => ({ ...acc, [i.external_id]: i.id }),
            {} as Record<string, number>,
          );
        default:
          return {} as Record<string, number>;
      }
    },
  });
};
