import { DeadlineChip } from 'app/modules/deadlines/components/DeadlineChip';
import {
  U21Form,
  U21FormField,
  U21Modal,
  U21Spacer,
  U21FormFieldTypes,
  U21Alert,
} from 'app/shared/u21-ui/components';
import { ArticleDeadlineResponse } from 'app/modules/deadlines/types';
import { ArticleDeadline, DeadlinesConfig } from 'app/modules/deadlines/models';
import { formatDistance } from 'date-fns';
import { useMemo, useState } from 'react';
import { formatDatetime } from 'app/shared/utils/date';
import { useEditArticleDeadline } from 'app/modules/deadlines/queries/useEditArticleDeadline';
import { useSelector } from 'react-redux';
import { selectHasEditDeadlinesPermission } from 'app/modules/session/selectors';

const FORM_ID = 'EDIT_ARTICLE_DEADLINE';

interface OwnProps {
  articleId: number;
  deadline?: ArticleDeadlineResponse | null;
  deadlineConfig?: DeadlinesConfig | null;
  disabled?: boolean;
  hideDeadline?: boolean;
  onEditSuccess?: (newDeadline: ArticleDeadline) => void;
}

/* 
    This chip renders a deadline and allows to extend it for any article. (as long as the user has the permission and it's not hidden)
*/
export const ArticleDeadlineChip = ({
  deadline,
  deadlineConfig,
  disabled,
  hideDeadline = false,
  onEditSuccess,
  articleId,
}: OwnProps) => {
  const { isPending: editDeadlineLoading, mutateAsync: asyncEditDeadline } =
    useEditArticleDeadline();

  // Modal ops
  const [open, setOpen] = useState(false);

  const [deadlineDate, startDate] = useMemo(() => {
    const deadlineDateInner = deadline?.deadline
      ? new Date(deadline.deadline)
      : undefined;
    const startDateInner = deadline?.start_date
      ? new Date(deadline.start_date)
      : new Date();
    return [deadlineDateInner, startDateInner];
  }, [deadline]);

  // Permissions and stuff
  const hasEditDeadlinePermission = useSelector(
    selectHasEditDeadlinesPermission,
  );

  if (hideDeadline) {
    return null;
  }
  return (
    <>
      <DeadlineChip
        articleDeadline={deadline || undefined}
        deadlinesConfig={deadlineConfig || undefined}
        onClick={
          hasEditDeadlinePermission && !disabled
            ? () => setOpen(true)
            : undefined
        }
      />
      <U21Modal
        actionButtonProps={{
          children: 'Save',
          form: FORM_ID,
          type: 'submit',
          loading: editDeadlineLoading,
        }}
        onClose={() => setOpen(false)}
        open={open}
        title="Edit Deadline"
      >
        <U21Form
          id={FORM_ID}
          initialValues={{ deadline: deadlineDate }}
          onSubmit={async (values) => {
            const { deadline: newDeadline } = values;

            if (newDeadline) {
              const duration = Math.round(
                (newDeadline.getTime() - startDate.getTime()) / 1000,
              );

              // Send update if there's no existing deadline or the deadline changed
              if (
                !deadlineDate ||
                newDeadline.getTime() !== deadlineDate.getTime()
              ) {
                try {
                  const res = await asyncEditDeadline({
                    article_id: articleId,
                    duration,
                  });
                  onEditSuccess?.(res);
                } catch {}
              }
            }
            setOpen(false);
          }}
        >
          {(formProps) => {
            const {
              values: { deadline: newDeadline },
            } = formProps;

            return (
              <U21Spacer spacing={2}>
                <U21FormField<Date>
                  fieldProps={{
                    clearable: false,
                  }}
                  required
                  label="New deadline"
                  name="deadline"
                  type={U21FormFieldTypes.DATE_TIME_PICKER}
                  validate={(value) => {
                    if (!value) {
                      return 'Required';
                    }
                    if (startDate && value <= startDate) {
                      return `Deadline must be after start date ${formatDatetime(
                        startDate,
                      )}`;
                    }
                    return undefined;
                  }}
                />
                {(() => {
                  if (startDate && newDeadline <= startDate) {
                    return (
                      <U21Alert>{`Deadline must be after start date ${formatDatetime(
                        startDate,
                      )}`}</U21Alert>
                    );
                  } else if (
                    deadlineDate &&
                    newDeadline.getTime() !== deadlineDate.getTime()
                  ) {
                    const duration = formatDistance(newDeadline, deadlineDate);
                    return (
                      <U21Alert severity="info">{`Deadline ${
                        newDeadline > deadlineDate ? 'extended' : 'shortened'
                      } by ${duration}`}</U21Alert>
                    );
                  }
                  return null;
                })()}
              </U21Spacer>
            );
          }}
        </U21Form>
      </U21Modal>
    </>
  );
};
