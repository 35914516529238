import { AiInvestigationInternalSource } from 'app/modules/investigations/types/responses';
import { U21DataChipType } from 'app/shared/u21-ui/components/dashboard/dataChip/U21DataChip';

export const CHIP_PINK = '#F9D4FF';
export const TYPE_TO_DATA_TYPE_MAP: Readonly<
  Record<AiInvestigationInternalSource['data_type'], U21DataChipType>
> = {
  ACTION_EVENT: U21DataChipType.ACTION_EVENT,
  ALERT: U21DataChipType.ALERT,
  ENTITY: U21DataChipType.ENTITY,
  INSTRUMENT: U21DataChipType.INSTRUMENT,
  TRANSACTION: U21DataChipType.TXN_EVENT,
  ATTACHMENT: U21DataChipType.ATTACHMENT,
};
