import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

// Components
import {
  U21Select,
  U21Section,
  U21Loading,
  U21NoData,
  U21SelectOptionProps,
} from 'app/shared/u21-ui/components';
import { NetworkAnalysis as NetworkAnalysisComponent } from 'app/modules/networkAnalysisRefresh/components/NetworkAnalysis';

// Helpers
import { formatEntityOptions, ValueField } from 'app/modules/search/helpers';

// Selectors
import {
  selectAlert,
  selectAlertAssociatedEntities,
} from 'app/modules/alerts/selectors';
import { useGetAlertInstruments } from 'app/modules/alerts/queries/useGetAlertInstruments';
import { BaseObjectType } from 'app/modules/networkAnalysisRefresh/types';
import { IconCreditCard } from '@u21/tabler-icons';

export const NetworkAnalysis = () => {
  const alertAssociatedEntities = useSelector(selectAlertAssociatedEntities);
  const { id: alertId } = useSelector(selectAlert);
  const {
    data: { instruments } = { count: null, instruments: [] },
    isLoading,
  } = useGetAlertInstruments(alertId, {
    limit: 100,
    offset: 1,
    sort_column: 'id',
    sort_direction: 'descending',
  });

  const [selectedObjectId, setSelectedObjectId] = useState<string | undefined>(
    undefined,
  );

  const mergedSelectOptions: U21SelectOptionProps<string>[] = useMemo(() => {
    const entityOptions = formatEntityOptions(
      alertAssociatedEntities,
      undefined,
      ValueField.EXTERNAL_ID,
    ).map((opt) => ({
      ...opt,
      value: `${BaseObjectType.ENTITY}_${opt.value}`,
      children: undefined,
    }));
    const instrumentOptions = instruments.map((instrument) => ({
      text: instrument.external_id,
      icon: <IconCreditCard />,
      value: `${BaseObjectType.INSTRUMENT}_${instrument.external_id}`,
    }));
    return [...entityOptions, ...instrumentOptions];
  }, [alertAssociatedEntities, instruments]);

  useEffect(() => {
    if (alertAssociatedEntities.length) {
      setSelectedObjectId(
        `${BaseObjectType.ENTITY}_${alertAssociatedEntities[0].external_id}`,
      );
    } else if (instruments.length) {
      setSelectedObjectId(
        `${BaseObjectType.INSTRUMENT}_${instruments[0].external_id}`,
      );
    }
  }, [alertAssociatedEntities, instruments]);

  const selectedEntity = useMemo(
    () =>
      alertAssociatedEntities.find(
        (e) =>
          e.external_id ===
          selectedObjectId?.replace(`${BaseObjectType.ENTITY}_`, ''),
      ),
    [alertAssociatedEntities, selectedObjectId],
  );

  const selectedInstrument = useMemo(
    () =>
      instruments.find(
        (i) =>
          i.external_id ===
          selectedObjectId?.replace(`${BaseObjectType.INSTRUMENT}_`, ''),
      ),
    [instruments, selectedObjectId],
  );

  if (!selectedObjectId || isLoading) {
    return (
      <U21Section>
        <U21Loading loading variant="graphic" />
      </U21Section>
    );
  }

  if (!selectedEntity && !selectedInstrument) {
    return <U21NoData />;
  }

  return (
    <>
      {mergedSelectOptions.length > 1 && (
        <SelectContainer>
          <U21Select
            label={(() => {
              if (alertAssociatedEntities.length && instruments.length) {
                return 'Select entity or instrument...';
              }
              if (instruments.length) {
                return 'Select instrument...';
              }
              return 'Select entity...';
            })()}
            clearable={false}
            onChange={(objectId: string) => {
              if (objectId) {
                setSelectedObjectId(objectId);
              }
            }}
            options={mergedSelectOptions}
            value={selectedObjectId}
          />
        </SelectContainer>
      )}
      {selectedEntity && (
        <NetworkAnalysisComponent
          baseObject={selectedEntity}
          baseObjectType={BaseObjectType.ENTITY}
        />
      )}
      {selectedInstrument && (
        <NetworkAnalysisComponent
          baseObject={selectedInstrument}
          baseObjectType={BaseObjectType.INSTRUMENT}
        />
      )}
    </>
  );
};

const SelectContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing(2)};
  `};
`;
