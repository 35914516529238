import { useMutation } from '@tanstack/react-query';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { post } from 'app/shared/utils/fetchr';
import pluralize from 'pluralize';
import { useDispatch } from 'react-redux';

export const useAddEntitiesToCase = (id: number) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (payload: { entities: string[] }) =>
      post<{ entities: string[] }>(`/cases/${id}/entities/add`, payload),
    onSuccess: (_, payload) => {
      dispatch(
        sendSuccessToast(
          `Successfully added ${pluralize(
            'entity',
            payload.entities.length,
            true,
          )} to case.`,
        ),
      );
    },
    onError: () => {
      dispatch(sendErrorToast('Failed to add entities to case.'));
    },
  });
};
