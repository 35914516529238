import React, { Component } from 'react';
import autoBindReact from 'auto-bind/react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Models
import { EntitySummary } from 'app/modules/entities/models';
import {
  RowMenuItem,
  SelectableCheckboxProps,
  TableConfig,
} from 'app/shared/pagination/models';
import { PaginationPayload } from 'app/shared/models';
import { TableConfigType } from 'app/shared/CustomConfig/models';

// Components
import PaginationTable from 'app/shared/pagination/components/PaginationTable';

// Selectors
import { selectLumosConfig } from 'app/modules/orgSettings/selectors';
import { selectEntityTableConfig } from 'app/shared/CustomConfig/selectors';

// Constants
// Utils
import assets from 'app/shared/utils/assets';
import routes, { openInNewTab } from 'app/shared/utils/routes';

// Helpers
import { getEntityExternalLinks } from 'app/modules/entities/helpers';

import { ValueField } from 'app/modules/search/helpers';
import { IconArrowRight } from '@u21/tabler-icons';

interface OwnProps {
  entities: EntitySummary[];
  loading: boolean;
  totalCount: number | null;
  update: (payload: PaginationPayload, tableConfig: TableConfig[]) => void;

  // optional
  ownPropsConfig?: TableConfig[];
  classNameProps?: string;
  disableFooter?: boolean;
  onRowClick: (e: MouseEvent, row: any) => void;
  renderCellValue?: (config: TableConfig, row: any) => React.ReactNode;
  selectableCheckboxProps?: SelectableCheckboxProps;
  // to highlight a selected row
  selectedRowId?: number | string;
  // to add gotoEntity on the dropdown
  addGotoEntityInDropdown?: boolean;

  // If we want to force the columns to not be sortable
  disableSorting?: boolean;
}

const handleOpenInNewTab = (entity: EntitySummary) => {
  // open in new tab
  const path = routes.lumos.entitiesId.replace(':id', String(entity.id));
  openInNewTab(path);
};

const mapStateToProps = (state: RootState) => {
  return {
    entityTableConfig: selectEntityTableConfig(state),
    lumosConfig: selectLumosConfig(state),
  };
};

type AllProps = ReturnType<typeof mapStateToProps> &
  RouteComponentProps &
  OwnProps;

class EntityTable extends Component<AllProps> {
  ROW_MENU_ITEMS: RowMenuItem[] = [];

  constructor(props: AllProps) {
    super(props);

    autoBindReact(this);
    this.updateRowMenuItems();
  }

  handleGoToEntity(entity: EntitySummary) {
    const { history } = this.props;
    // go to entity details
    const path = routes.lumos.entitiesId.replace(':id', String(entity.id));
    history.push(path);
  }

  // had to explicity use the fat arrow function here as the END_OF_ROW_ICON uses this function before autoBindReact can bind it
  handleIconClick = (e: MouseEvent, entity: EntitySummary) => {
    // open entity in new tab if cmd + click
    if (e.metaKey) {
      handleOpenInNewTab(entity);
      return;
    }
    // open in new tab otherwise
    this.handleGoToEntity(entity);
  };

  onRowClick(e: MouseEvent, entity: EntitySummary) {
    const { onRowClick } = this.props;
    // open entity in new tab if cmd + click
    if (e.metaKey) {
      handleOpenInNewTab(entity);
      return;
    }

    onRowClick(e, entity);
  }

  update(payload: PaginationPayload) {
    const { update, entityTableConfig } = this.props;
    update(payload, entityTableConfig);
  }

  updateRowMenuItems() {
    const { addGotoEntityInDropdown, lumosConfig, history } = this.props;
    if (addGotoEntityInDropdown) {
      this.ROW_MENU_ITEMS.push({
        key: 'entity',
        text: 'Visit Entity Page',
        icon: IconArrowRight,
        onClick: (entity: EntitySummary) => {
          const path = routes.lumos.entitiesId.replace(
            ':id',
            String(entity.id),
          );
          history.push(path);
        },
        shouldDisable: () => {
          // always enabled
          return false;
        },
      });
    }

    this.ROW_MENU_ITEMS = [
      ...this.ROW_MENU_ITEMS,
      ...getEntityExternalLinks(lumosConfig),
    ];
  }

  render() {
    const {
      entities,
      selectableCheckboxProps,
      totalCount,
      loading,
      selectedRowId,
      classNameProps,
      disableFooter,
      renderCellValue,
      ownPropsConfig,
      entityTableConfig,
      disableSorting,
    } = this.props;

    const entityTableConfigToUse = ownPropsConfig || entityTableConfig;
    const tableConfig = disableSorting
      ? entityTableConfigToUse.map((column) => ({
          ...column,
          sortable: false,
        }))
      : entityTableConfigToUse;

    return (
      <PaginationTable
        update={this.update}
        totalCount={totalCount}
        dataType="entities"
        dataTypeIcon={assets.icons.entity}
        config={tableConfig}
        disableFooter={disableFooter}
        rows={entities}
        onRowClick={this.onRowClick}
        classNameProps={classNameProps}
        renderCellValue={renderCellValue}
        selectableCheckboxProps={selectableCheckboxProps}
        rowMenuItems={this.ROW_MENU_ITEMS}
        loading={loading}
        selectedRowId={selectedRowId}
        endOfRowIcon={{
          icon: IconArrowRight,
          onClick: this.handleIconClick,
        }}
        tableProps={{
          selectable: true,
        }}
        tableConfigType={TableConfigType.ENTITY_TABLE}
        valueField={ValueField.EXTERNAL_ID}
      />
    );
  }
}

export default connect(mapStateToProps)(withRouter(EntityTable));
