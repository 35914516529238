import { createSelector } from 'reselect';

// Selectors
import { selectAlert } from 'app/modules/alerts/selectors';
import {
  selectAlertDetailsTabs,
  selectCustomAlertTabsConfig,
} from 'app/modules/alerts/components/AlertDetails/selectors';
import { selectCustomConfigs } from 'app/shared/CustomConfig/selectors';
import {
  selectCustomChecklistDefinition,
  selectCustomChecklistHistory,
} from 'app/shared/customChecklist/selectors';
import { selectInvestigation } from 'app/modules/investigations/selectors/investigations';
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';

// Models
import {
  InvestigationStatus,
  InvestigationTabs,
  InvestigationType,
} from 'app/modules/investigations/models';
import { TabConfigType } from 'app/shared/CustomConfig/models';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';

// Utils
import { doubleSelector } from 'app/modules/investigations/selectors/utils';
import { getAllTabs } from 'app/modules/alerts/components/AlertDetails/Tabs/allTabs';
import { getDefaultTabObject } from 'app/modules/investigations/helpers';
import { generateCaseTabs } from 'app/modules/cases/tabs';
import { FeatureFlag } from 'app/shared/featureFlags/models';
import { selectConsortiumEnabled } from 'app/modules/orgSettings/selectors';

import { selectSideBySideCaseEnabled } from 'app/shared/components/GaBanner/selector';

const selectAlertTabsSelector = createSelector(selectAlert, (alert) =>
  selectAlertDetailsTabs(getAllTabs(alert)),
);

const selectAssociatedTabs = doubleSelector(selectAlertTabsSelector);

const selectAlertCustomTabsSelector = createSelector(
  selectAssociatedTabs,
  (associatedTabs) => selectCustomAlertTabsConfig(associatedTabs),
);

export const selectAlertCustomTabs = doubleSelector(
  selectAlertCustomTabsSelector,
);

export const selectCaseCustomTabs = createSelector(
  [
    selectInvestigation(InvestigationType.CASE),
    selectCustomConfigs,
    selectCustomChecklistDefinition,
    selectCustomChecklistHistory,
    selectFeatureFlags,
    selectHasReadEventsPermission,
    selectConsortiumEnabled,
    selectSideBySideCaseEnabled,
  ],
  (
    investigation,
    configs,
    customChecklistDefinition,
    submissionHistory,
    featureFlags,
    hasReadEventsPermission,
    consortiumEnabled,
    sideBySideInvestigationsEnabled,
  ) => {
    const defaultTabs = generateCaseTabs({
      id: investigation.id,
      readOnly:
        investigation.status === InvestigationStatus.CLOSED ||
        investigation.status === InvestigationStatus.IN_REVIEW,
      hasChecklist: Boolean(customChecklistDefinition),
      hasHistory: submissionHistory.length > 0,
      comprehensiveAuditTrailsEnabled:
        featureFlags[FeatureFlag.COMPREHENSIVE_AUDIT_TRAILS],
      showFraudLossTab:
        Boolean(featureFlags[FeatureFlag.FRAUD_DASHBOARD]) &&
        hasReadEventsPermission,
      consortiumEnabled,
      sideBySideInvestigationsEnabled,
    });

    const defaultCaseTabObject = getDefaultTabObject(defaultTabs);

    const customTabDefinition =
      configs[TabConfigType.CASE_TABS]?.definition || [];

    if (!customTabDefinition.length) {
      return defaultTabs;
    }

    let remainingCustomTabs = { ...defaultCaseTabObject };
    const customTabOrder = customTabDefinition.reduce<InvestigationTabs[]>(
      (acc, tabConfig) => {
        const tabDetails: InvestigationTabs =
          defaultCaseTabObject[tabConfig.key];

        // This is a function that excludes the current tabConfig.key
        const removeProperty = ({ [tabConfig.key]: _, ...rest }) => rest;

        if (tabDetails) {
          acc.push({ ...tabDetails, hidden: tabConfig.hidden });
          // We can invoke the function to remove the current tabConfig.key from the remainingCustomTabs object
          remainingCustomTabs = removeProperty(remainingCustomTabs);
        }
        return acc;
      },
      [],
    );

    return [...customTabOrder, ...Object.values(remainingCustomTabs)];
  },
);
