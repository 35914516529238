import { GetEntitiesResponse } from 'app/modules/entitiesRefresh/responses';

export const DEFAULT_GET_ENTITIES_RESPONSE: GetEntitiesResponse = {
  count: null,
  entities: [],
};

export type EntityDetailsTabType = {
  key: string;
  hidden: boolean;
  label: string;
};

export const ENTITY_DETAILS_DEFAULT_TABS: Record<string, EntityDetailsTabType> =
  {
    transactions: {
      key: 'transactions',
      hidden: false,
      label: 'Transactions',
    },

    'action-events': {
      key: 'action-events',
      hidden: false,
      label: 'Action Events',
    },

    risk: {
      key: 'risk',
      hidden: false,
      label: 'Risk',
    },

    documents: {
      key: 'documents',
      hidden: false,
      label: 'Documents',
    },

    instruments: {
      key: 'instruments',
      hidden: false,
      label: 'Instruments',
    },

    'rule-whitelist': {
      key: 'rule-whitelist',
      hidden: false,
      label: 'Rule Silencing',
    },

    'network-analysis': {
      key: 'network-analysis',
      hidden: false,
      label: 'Network Analysis',
    },

    'link-analysis': {
      key: 'link-analysis',
      hidden: false,
      label: 'Link Analysis',
    },

    relationships: {
      key: 'relationships',
      hidden: false,
      label: 'Relationships',
    },

    'geo-location': {
      key: 'geo-location',
      hidden: false,
      label: 'Geolocation',
    },

    'consortium-hits': {
      key: 'consortium-hits',
      hidden: false,
      label: 'Consortium Hits',
    },
  } as const;
