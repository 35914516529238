import { AIAgentSidebarDefinition } from 'app/modules/sidebar/models';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';
import { useGetAIResults } from 'app/modules/aiAgentResults/queries/useGetAIResults';
import { useMemo } from 'react';
import {
  U21Markdown,
  U21NoData,
  U21Section,
  U21Skeleton,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import styled from 'styled-components';

interface Props {
  data: AIAgentSidebarDefinition['data'];
}

export const SidebarAIAgent = (props: Props) => {
  const {
    data: { id, type, agentID },
  } = props;

  const { data, isLoading } = useGetAIResults({
    relation_id: id,
    relation_type: type,
  });

  const signal = useMemo(
    () => data?.signals.find((i) => i.agent_id === agentID),
    [agentID, data],
  );

  return (
    <U21SideMenu
      loading={isLoading}
      title={signal ? signal?.title : <U21Skeleton width={100} />}
    >
      {(() => {
        if (!signal || !signal.tasks.length) {
          return <U21NoData />;
        }
        return (
          <U21Spacer>
            {signal.tasks.map((i, idx) => (
              <StyledU21Section
                key={i.id}
                collapsible
                collapsed={idx > 0}
                title={
                  <U21Typography variant="subtitle2">
                    {i.question}
                  </U21Typography>
                }
              >
                <U21Markdown>{i.answer}</U21Markdown>
              </StyledU21Section>
            ))}
          </U21Spacer>
        );
      })()}
    </U21SideMenu>
  );
};

// quick and dirty solution to get the styling
// ok since design will change
const StyledU21Section = styled(U21Section)`
  background-color: ${(props) => props.theme.palette.background.neutral};
  // keep border but same color as background so content positioning is the same
  border-color: ${(props) => props.theme.palette.background.neutral};

  .MuiCardHeader-root {
    padding: 16px;
  }

  .MuiCardHeader-title {
    text-transform: none;
  }

  .MuiCardContent-root {
    padding-left: 16px;
    padding-right: 16px;
  }
  // give it higher specificity to override
  .MuiCardContent-root.MuiCardContent-root.MuiCardContent-root {
    padding-bottom: 16px;
  }
`;
