// Components
import { IconCreditCard } from '@u21/tabler-icons';
import {
  DataProps,
  SidebarExternalLinkList,
} from 'app/modules/sidebar/components/SidebarExternalLinkList';
import {
  U21Subsection,
  U21TitleCountLabel,
  U21Typography,
} from 'app/shared/u21-ui/components';

// Models
import { InstrumentSummary } from 'app/modules/txnInstruments/models';

// Helpers
import { useMemo } from 'react';
import { formatDatetime } from 'app/shared/utils/date';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { useSelector } from 'react-redux';
import { selectHasReadInstrumentsPermission } from 'app/modules/session/selectors';

interface Props {
  count: number | null;
  instruments: InstrumentSummary[];
}

export const SidebarAssociatedInstruments = ({ count, instruments }: Props) => {
  const hasReadInstrumentsPermission = useSelector(
    selectHasReadInstrumentsPermission,
  );
  const list = useMemo(
    () =>
      instruments.map<DataProps>((i) => ({
        label: formatDatetime(i.created_at),
        value: i.external_id,
        id: i.id,
        route: hasReadInstrumentsPermission
          ? ROUTES_MAP.instrumentsId.path.replace(':id', String(i.id))
          : undefined,
      })),
    [instruments, hasReadInstrumentsPermission],
  );
  return (
    <U21Subsection
      aria-label="Flagged Instruments"
      collapsible
      collapsed
      shaded
      title={
        <U21TitleCountLabel count={count} label="">
          Flagged Instruments
        </U21TitleCountLabel>
      }
      titleIcon={<IconCreditCard />}
    >
      {instruments.length ? (
        <SidebarExternalLinkList list={list} />
      ) : (
        <U21Typography variant="caption" color="text.secondary">
          No flagged instruments
        </U21Typography>
      )}
    </U21Subsection>
  );
};
