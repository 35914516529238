import React from 'react';
import {
  U21Modal,
  U21Alert,
  U21Spacer,
  U21Chip,
  U21Form,
  U21FormField,
  U21Typography,
  U21ShowMoreList,
} from 'app/shared/u21-ui/components';
import { IconCreditCard } from '@u21/tabler-icons';
import { BLOCKED_REASONS_OPTIONS_WITH_OTHER } from 'app/modules/detectionModels/components/scenarioWidgets/consortium/constants';
import styled from 'styled-components';
import { useActionInstruments } from 'app/modules/instruments/queries/useActionInstruments';
import { InstrumentActionStatus } from 'app/modules/instruments/constants';
import { toSentenceCase } from 'app/shared/utils/string';

const MAX_NOTE_LENGTH = 5000;
interface BlockedInstrumentModalProps {
  open: boolean;
  onClose: () => void;
  desiredActionStatus?: InstrumentActionStatus;
  instrumentIds: string[];
  // for single instruments actioned from the details page
  // we need the unit21 id to invalidate the cache
  instrumentUnit21Id?: string | number;
}

export const InstrumentActionModal = ({
  open,
  onClose,
  desiredActionStatus,
  instrumentIds,
  instrumentUnit21Id,
}: BlockedInstrumentModalProps) => {
  const { isPending, mutateAsync: actionInstrument } =
    useActionInstruments(instrumentUnit21Id);

  const isDesiredActionStatusDeactivated =
    desiredActionStatus === InstrumentActionStatus.DEACTIVATED;
  const isDesiredActionStatusLocked =
    desiredActionStatus === InstrumentActionStatus.LOCKED;
  const isDesiredActionStatusUnlock =
    desiredActionStatus === InstrumentActionStatus.UNLOCKED;

  const getActionReason = (actionReason: string): string => {
    if (actionReason === 'Other') {
      return 'Other reason';
    }
    return actionReason;
  };

  const getModalTitle = () => {
    if (isDesiredActionStatusDeactivated) {
      return 'Deactivate Instrument';
    }
    if (isDesiredActionStatusLocked) {
      return 'Lock Instrument';
    }
    return 'Unlock Instrument';
  };

  const getActionButtonText = () => {
    if (isDesiredActionStatusDeactivated) {
      return 'Deactivate';
    }
    if (isDesiredActionStatusLocked) {
      return 'Lock';
    }
    return 'Unlock';
  };

  return (
    <U21Modal
      open={open}
      onClose={onClose}
      title={getModalTitle()}
      size="small"
      actionButtonProps={{
        color: isDesiredActionStatusUnlock ? 'primary' : 'error',
        children: getActionButtonText(),
        loading: isPending,
        type: 'submit',
        form: 'BLOCKED_INSTRUMENT_FORM',
      }}
    >
      <U21Spacer spacing={3}>
        <U21Form<{ action_reason?: string; note?: string }>
          id="BLOCKED_INSTRUMENT_FORM"
          initialValues={{}}
          onSubmit={async (values, form) => {
            try {
              await actionInstrument({
                action_status: desiredActionStatus as string,
                action_reason: values.action_reason,
                instrument_external_ids: instrumentIds,
                action_note: values.note,
              });
              form.reset();
              onClose();
            } catch {}
          }}
        >
          {({ values: currentFormValues }) => {
            return (
              <U21Spacer spacing={3}>
                {isDesiredActionStatusDeactivated && (
                  <U21Alert severity="warning">
                    Please review what you selected. This action is
                    irreversible.
                  </U21Alert>
                )}
                {!isDesiredActionStatusDeactivated &&
                  instrumentIds.length > 1 && (
                    <U21Alert severity="info">
                      Any instruments which are already{' '}
                      {isDesiredActionStatusLocked ? 'locked' : 'unlocked'} will
                      not be affected.
                    </U21Alert>
                  )}
                <U21Spacer spacing={0.5}>
                  <U21Typography variant="subtitle2">Disposition</U21Typography>
                  <U21Typography variant="body2">
                    {toSentenceCase(desiredActionStatus)}
                  </U21Typography>
                </U21Spacer>
                <U21Spacer spacing={0.5}>
                  <U21Typography variant="subtitle2">Instruments</U21Typography>
                  <U21ShowMoreList
                    value={instrumentIds}
                    displayFunc={(i) => (
                      <StyledU21Chip key={i} icon={<IconCreditCard />}>
                        {i}
                      </StyledU21Chip>
                    )}
                  />
                </U21Spacer>
                {!isDesiredActionStatusUnlock && (
                  <U21FormField
                    label="Reason"
                    name="action_reason"
                    required
                    type="SELECT"
                    validate={(value) => {
                      if (
                        isDesiredActionStatusDeactivated ||
                        isDesiredActionStatusLocked
                      ) {
                        return value ? undefined : 'Field is required';
                      }
                      return undefined;
                    }}
                    fieldProps={{
                      options: BLOCKED_REASONS_OPTIONS_WITH_OTHER,
                      clearable: false,
                    }}
                  />
                )}
                <U21FormField
                  label="Note"
                  name="note"
                  required={!isDesiredActionStatusUnlock}
                  type="TEXTAREA"
                  fieldProps={{
                    placeholder: isDesiredActionStatusUnlock
                      ? 'Add an optional note'
                      : 'Add a note',
                  }}
                  validate={(value: string | undefined) => {
                    if (!isDesiredActionStatusUnlock && !value) {
                      return 'Field is required';
                    }

                    if (value && value.length > MAX_NOTE_LENGTH) {
                      return `Note must be less than ${MAX_NOTE_LENGTH} characters`;
                    }
                    return undefined;
                  }}
                />
                {currentFormValues.action_reason ? (
                  <U21Typography variant="body2" color="text.primary">
                    Actioning{' '}
                    <b>
                      {instrumentIds.length > 1
                        ? `${instrumentIds.length} instruments`
                        : `Instrument ${instrumentIds[0]}`}
                    </b>{' '}
                    due to{' '}
                    <b>{getActionReason(currentFormValues.action_reason)}</b>.
                  </U21Typography>
                ) : null}
              </U21Spacer>
            );
          }}
        </U21Form>
      </U21Spacer>
    </U21Modal>
  );
};

const StyledU21Chip = styled(U21Chip)`
  overflow: hidden;
`;
