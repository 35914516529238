// Components
import { selectAlert, selectAlertEntities } from 'app/modules/alerts/selectors';

// Selectors
import { useSelector } from 'react-redux';

// Constants
import { useMemo } from 'react';
import { RuleGraph } from 'app/modules/detectionModels/components/RuleGraph';
import { useFetchAlertGraphRuleMatches } from 'app/modules/alerts/queries/useFetchAlertGraphRuleMatches';
import { graphifyMatchData } from 'app/modules/detectionModels/helpers';

export const RuleGraphTab = () => {
  const alert = useSelector(selectAlert);
  const alertEntities = useSelector(selectAlertEntities);
  const { data: graphRuleMatches } = useFetchAlertGraphRuleMatches(alert.id);

  const graph = useMemo(
    () =>
      graphRuleMatches
        ? graphifyMatchData({
            matches: graphRuleMatches,
            entities: alertEntities,
          })
        : null,
    [graphRuleMatches, alertEntities],
  );
  if (!graph) {
    return null;
  }

  return <RuleGraph graph={graph} />;
};
