import { IconLayoutColumns, IconRefresh } from '@u21/tabler-icons';
import { AI_ADMIN_TRACKING_COLUMNS_CONFIG } from 'app/modules/aiAdmin/components/aiAdminTracking/columns';
import { AIAdminTracking } from 'app/modules/aiAdmin/components/aiAdminTracking/models';
import {
  AI_ADMIN_INFO_MAP,
  DEFAULT_GET_AI_ADMIN_TRACKING_RESPONSE,
} from 'app/modules/aiAdmin/constants';
import { ALL_AI_ADMIN_TRACKING_FILTER_OPTIONS } from 'app/modules/aiAdmin/filters';
import { useGetAiAdminTracking } from 'app/modules/aiAdmin/queries/useGetAiAdminTracking';
import { createAiAdminTrackingFilterPayload } from 'app/modules/aiAdmin/utils';
import { Filters } from 'app/modules/filters/components/Filters';
import { Filter } from 'app/modules/filters/models';
import { useToggleAiAdminTrackingSidebar } from 'app/modules/sidebar/hooks';
import { AiAdminTypes } from 'app/modules/sidebar/models';
import { selectSidebarAiAdminTrackingID } from 'app/modules/sidebar/selectors';
import { toggleTableConfigSidebar } from 'app/modules/sidebar/slice';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { selectAiAdminTrackingTableConfig } from 'app/shared/CustomConfig/selectors';
import {
  useLocalStorage,
  useLocalStorageState,
} from 'app/shared/hooks/useLocalStorage';
import {
  U21Button,
  U21LoadError,
  U21Section,
  U21Spacer,
  U21Table,
  U21TablePreference,
  U21TableState,
  U21TitleCountLabel,
} from 'app/shared/u21-ui/components';
import { DEFAULT_TABLE_PREFRENCES } from 'app/shared/u21-ui/components/display/table/constants';
import {
  createPaginationPayload,
  createTableColumnConfig,
} from 'app/shared/utils/table';
import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableInstance } from 'react-table';

export const AiAdminTracking = () => {
  const tableInstanceRef = useRef<TableInstance<AIAdminTracking>>(null);

  const dispatch = useDispatch();
  const toggleAiAdminTrackingSidebar = useToggleAiAdminTrackingSidebar();

  const tableConfig = useSelector(selectAiAdminTrackingTableConfig);
  const sidebarAiAdminTrackingID = useSelector(selectSidebarAiAdminTrackingID);

  const [tablePreferences, setTablePreferences] =
    useLocalStorage<U21TablePreference>(
      LocalStorageKeys.AI_ADMIN_TRACKING_TABLE_PREFERENCES,
      DEFAULT_TABLE_PREFRENCES,
    );

  const [filters, setFilters] = useLocalStorageState<Filter[]>(
    LocalStorageKeys.AI_ADMIN_TRACKING_FILTERS,
    [],
  );

  const [pinned, setPinned] = useLocalStorageState<string[]>(
    LocalStorageKeys.AI_ADMIN_TRACKING_PINNED_FILTERS,
    [],
  );

  const [tableState, setTableState] = useState<U21TableState>({
    page: 1,
    pageSize: tablePreferences.pageSize,
    sortBy: tablePreferences.sortBy,
  });

  const payload = useMemo(
    () => ({
      ...createPaginationPayload(tableState),
      ...createAiAdminTrackingFilterPayload(filters),
    }),
    [filters, tableState],
  );

  const {
    data: {
      count,
      tracking_info: info,
    } = DEFAULT_GET_AI_ADMIN_TRACKING_RESPONSE,
    isPending,
    isError,
    isRefetching,
    refetch,
  } = useGetAiAdminTracking(payload);

  const dataMap = useMemo<Record<number, AIAdminTracking>>(
    () =>
      info.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {}),
    [info],
  );

  const highlighted = useMemo(
    () => (sidebarAiAdminTrackingID ? [sidebarAiAdminTrackingID] : undefined),
    [sidebarAiAdminTrackingID],
  );

  const columns = useMemo(
    () =>
      createTableColumnConfig(tableConfig, AI_ADMIN_TRACKING_COLUMNS_CONFIG),
    [tableConfig],
  );

  return (
    <U21Section
      titleIcon={AI_ADMIN_INFO_MAP[AiAdminTypes.AI_ADMIN_TRACKING].icon}
      action={
        <U21Spacer horizontal>
          <U21Button onClick={() => refetch()} startIcon={<IconRefresh />}>
            Refresh
          </U21Button>

          <U21Button
            onClick={() =>
              dispatch(
                toggleTableConfigSidebar({
                  tableConfigType: TableConfigType.AI_ADMIN_TRACKING_TABLE,
                }),
              )
            }
            startIcon={<IconLayoutColumns />}
          >
            Choose Columns
          </U21Button>
        </U21Spacer>
      }
      title={
        <U21TitleCountLabel loading={isPending} count={count} label="info">
          {AI_ADMIN_INFO_MAP[AiAdminTypes.AI_ADMIN_TRACKING].title}
        </U21TitleCountLabel>
      }
    >
      <U21Spacer spacing={3}>
        <Filters
          filters={filters}
          onChange={(newFilters) => {
            setFilters(newFilters);
            setTableState((oldTableState) => ({
              ...oldTableState,
              page: 1,
            }));
            tableInstanceRef.current?.gotoPage(0);
          }}
          onPinChange={setPinned}
          options={ALL_AI_ADMIN_TRACKING_FILTER_OPTIONS}
          pinned={pinned}
        />
        {isError ? (
          <U21LoadError
            label="AI investigation tracking info"
            onTryAgain={() => refetch()}
          />
        ) : (
          <U21Table
            columns={columns}
            count={count}
            data={info}
            defaultSortBy={tablePreferences.sortBy}
            defaultPageSize={tablePreferences.pageSize}
            defaultColumnWidths={tablePreferences.columnWidths}
            highlighted={highlighted}
            label="tracking info"
            loading={isPending}
            manualPagination
            onPreferenceChange={(value) => setTablePreferences(value)}
            onRefresh={() => refetch()}
            onRowClick={(id: number | string, _, e: KeyboardEvent) => {
              toggleAiAdminTrackingSidebar(dataMap[id], tableConfig, e);
            }}
            onStateChange={setTableState}
            refreshLoading={isRefetching}
            tableInstanceRef={tableInstanceRef}
          />
        )}
      </U21Spacer>
    </U21Section>
  );
};
