import { U21TableColumn } from 'app/shared/u21-ui/components';
import { AIAdminTracking } from 'app/modules/aiAdmin/components/aiAdminTracking/models';

const CREATED_AT_COLUMN: U21TableColumn<AIAdminTracking, string> = {
  id: 'created_at',
  accessor: 'created_at',
  Header: 'Created at',
  type: 'datetime',
};

const UPDATED_AT_COLUMN: U21TableColumn<AIAdminTracking, string> = {
  id: 'updated_at',
  accessor: 'updated_at',
  Header: 'Updated at',
  type: 'datetime',
};

const FEEDBACK_COLUMN: U21TableColumn<AIAdminTracking, string> = {
  id: 'feedback',
  accessor: 'feedback',
  Header: 'Feedback',
  disableSortBy: true,
};

const META_COLUMN: U21TableColumn<AIAdminTracking, Record<string, any>> = {
  id: 'meta',
  accessor: 'meta',
  Header: 'Meta',
  disableSortBy: true,
};

const TAGS_COLUMN: U21TableColumn<AIAdminTracking, string[]> = {
  id: 'tags',
  accessor: 'tags',
  Header: 'Tags',
  type: 'list',
  disableSortBy: true,
};

export const AI_ADMIN_TRACKING_COLUMNS_CONFIG: Record<
  string,
  U21TableColumn<AIAdminTracking, any>
> = {
  [CREATED_AT_COLUMN.id]: CREATED_AT_COLUMN,
  [UPDATED_AT_COLUMN.id]: UPDATED_AT_COLUMN,
  [FEEDBACK_COLUMN.id]: FEEDBACK_COLUMN,
  [META_COLUMN.id]: META_COLUMN,
  [TAGS_COLUMN.id]: TAGS_COLUMN,
};
