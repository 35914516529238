import { IconBriefcase } from '@u21/tabler-icons';
import { CreateCaseLocationState } from 'app/modules/cases/models';
import { useAddEntitiesToCase } from 'app/modules/cases/queries/useAddEntitiesToCase';
import { ShortEntityResponse } from 'app/modules/entities/types';
import { ENTITY_COLUMN_CONFIG } from 'app/modules/entitiesRefresh/columns';
import { useNetworkAnalysisContext } from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';
import { EntityGroupItem } from 'app/modules/networkAnalysisRefresh/types';
import { selectEntityTableConfig } from 'app/shared/CustomConfig/selectors';
import {
  U21Spacer,
  U21Switch,
  U21Table,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { U21CaseSelect } from 'app/shared/u21-ui/components/dashboard/U21CaseSelect';
import { U21Modal } from 'app/shared/u21-ui/components/layout/modal/U21Modal';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { createTableColumnConfig } from 'app/shared/utils/table';
import pluralize from 'pluralize';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

export const SelectCaseObjectsModal = ({
  selectCaseObjectsModalOpen,
  setSelectCaseObjectsModalOpen,
  currentCaseId,
}: {
  selectCaseObjectsModalOpen: boolean;
  setSelectCaseObjectsModalOpen: Dispatch<SetStateAction<boolean>>;
  currentCaseId?: number;
}) => {
  const [selectedCaseId, setSelectedCaseId] = useState<number | undefined>(
    currentCaseId,
  );

  const {
    networkGroups: { objects: filteredObjects },
    fetchedEntities,
    caseObjects,
    setCaseObjects,
    baseObjectType,
    noFiltersApplied,
    isFetchingEntities,
  } = useNetworkAnalysisContext();
  const [applyFilters, setApplyFilters] = useState<boolean>(false);
  const entities: ShortEntityResponse[] = useMemo(() => {
    if (applyFilters) {
      return Object.values(filteredObjects).reduce<ShortEntityResponse[]>(
        (acc, e: EntityGroupItem) => {
          const entity = fetchedEntities[e.external_id];
          if (entity) {
            acc.push(entity);
          }
          return acc;
        },
        [],
      );
    }
    return Object.values(fetchedEntities);
  }, [fetchedEntities, applyFilters, filteredObjects]);

  const entityTableConfig = useSelector(selectEntityTableConfig);
  const columns = useMemo(() => {
    return [
      ...createTableColumnConfig<ShortEntityResponse>(
        entityTableConfig,
        ENTITY_COLUMN_CONFIG,
      ),
    ];
  }, [entityTableConfig]);

  const { mutateAsync: editCase, isPending: isAddingEntitiesToCase } =
    useAddEntitiesToCase(selectedCaseId ?? 0);

  return (
    <StyledModal
      loading={isFetchingEntities}
      open={selectCaseObjectsModalOpen}
      onClose={() => setSelectCaseObjectsModalOpen(false)}
      title={selectedCaseId ? 'Add to case' : 'Create case'}
      size="large"
      actionButtonProps={{
        disabled: caseObjects.length === 0,
        children: selectedCaseId
          ? `Add ${pluralize(baseObjectType, caseObjects.length, true)} to case`
          : `Create new case with ${pluralize(baseObjectType, caseObjects.length, true)}`,
        startIcon: <IconBriefcase />,
        color: 'primary',
        ...(!selectedCaseId
          ? {
              to: {
                pathname: ROUTES_MAP.createCase.path,
                state: {
                  entities: caseObjects,
                } satisfies CreateCaseLocationState,
              },
            }
          : {
              loading: isAddingEntitiesToCase,
            }),
      }}
      onAction={async () => {
        if (selectedCaseId) {
          try {
            await editCase({ entities: caseObjects });
            setSelectCaseObjectsModalOpen(false);
          } catch {}
        } else {
          setSelectCaseObjectsModalOpen(false);
        }
      }}
      onExited={() => {
        setCaseObjects([]);
        setApplyFilters(false);
        setSelectedCaseId(currentCaseId);
      }}
    >
      <U21Spacer spacing={4}>
        <U21Spacer spacing={2}>
          <U21Typography variant="body1">
            Select a case to add entities to, or create a new case.
          </U21Typography>
          <U21CaseSelect
            onChange={(value) => setSelectedCaseId(value)}
            value={selectedCaseId}
            label="Select case"
          />
        </U21Spacer>

        {!noFiltersApplied && (
          <U21Spacer horizontal align="center">
            <U21Switch
              checked={applyFilters}
              onChange={() => {
                setCaseObjects([]);
                setApplyFilters(!applyFilters);
              }}
            />
            <U21Typography>Apply current filters</U21Typography>
          </U21Spacer>
        )}
        <U21Table
          columns={columns}
          data={entities}
          selectable
          selected={caseObjects}
          onRowSelect={(selected: string[]) => setCaseObjects(selected)}
          getRowID={(row) => row.external_id}
        />
      </U21Spacer>
    </StyledModal>
  );
};

const StyledModal = styled(U21Modal)`
  [class^='U21ModalHeader__Container'],
  [class^='U21ModalFooter__Container'] {
    z-index: 1000 !important;
  }
`;
