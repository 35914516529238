import { useQuery } from '@tanstack/react-query';
import { post } from 'app/shared/utils/fetchr';
import { useSelector } from 'react-redux';
import { selectHasEditAiAdminDashboardPermission } from 'app/modules/session/selectors';
import { PaginatedAiAdminTrackingPayload } from 'app/modules/aiAdmin/requests';
import { PaginatedAiAdminTrackingResponse } from 'app/modules/aiAdmin/responses';
import { AI_ADMIN_QUERY_KEYS } from 'app/modules/aiAdmin/queries/keys';

export const useGetAiAdminTracking = (
  payload: PaginatedAiAdminTrackingPayload,
) => {
  const hasEditAiAdminDashboardPermissions = useSelector(
    selectHasEditAiAdminDashboardPermission,
  );

  return useQuery({
    enabled: hasEditAiAdminDashboardPermissions,
    queryFn: () =>
      post<PaginatedAiAdminTrackingResponse>(
        `/ai-admin-dashboard/tracking-info`,
        payload,
      ),
    queryKey: AI_ADMIN_QUERY_KEYS.getAiAdminTracking(payload),
  });
};
