import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNetworkAnalysisContext } from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';
import { NETWORK_ANALYSIS_QUERY_KEYS } from 'app/modules/networkAnalysisRefresh/queries/keys';
import { BaseObjectType } from 'app/modules/networkAnalysisRefresh/types';
import { post } from 'app/shared/utils/fetchr';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export interface NetworkAnalysisStatusResponse {
  status:
    | 'QUEUED'
    | 'IN_PROGRESS'
    | 'FINISHED_SUCCESSFULLY'
    | 'FINISHED_WITH_ERRORS';
  queued_at: string;
  started_at: string;
  finished_at: string;
}

const getNetworkAnalysisStatus = (entityExternalId: string) =>
  post<NetworkAnalysisStatusResponse>(`/entities/network-analysis/status`, {
    entity_external_id: entityExternalId,
  });

const getInstrumentNetworkAnalysisStatus = (instrumentExternalId: string) =>
  post<NetworkAnalysisStatusResponse>(`/instruments/network-analysis/status`, {
    instrument_external_id: instrumentExternalId,
  });

export const usePollNetworkAnalysisStatus = (
  externalId: string,
  baseObjectType: BaseObjectType,
) => {
  const { analysisIsPending: enabled, setAnalysisIsPending } =
    useNetworkAnalysisContext();

  const query = useQuery({
    queryKey: NETWORK_ANALYSIS_QUERY_KEYS.getNetworkAnalysisStatusKey(
      externalId,
      baseObjectType,
    ),
    queryFn: () =>
      baseObjectType === BaseObjectType.ENTITY
        ? getNetworkAnalysisStatus(externalId)
        : getInstrumentNetworkAnalysisStatus(externalId),
    refetchInterval: (q) =>
      !(q.state.status === 'error') &&
      (enabled ||
      !q.state.data?.status ||
      q.state.data?.status === 'QUEUED' ||
      q.state.data?.status === 'IN_PROGRESS'
        ? 1500
        : undefined), // refetch every 1.5 seconds
  });

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      (query.data?.status === 'FINISHED_SUCCESSFULLY' ||
        query.data?.status === 'FINISHED_WITH_ERRORS') &&
      !query.isFetching &&
      enabled
    ) {
      // invalidate the network analysis query so that the UI will update
      queryClient.invalidateQueries({
        queryKey: NETWORK_ANALYSIS_QUERY_KEYS.getNetworkAnalysisKey(
          externalId,
          baseObjectType,
        ),
      });
      // disable polling
      setAnalysisIsPending(false);
    }
  }, [
    query.data,
    enabled,
    queryClient,
    dispatch,
    externalId,
    baseObjectType,
    query.isFetching,
    setAnalysisIsPending,
  ]);
  return query;
};
