import { useMemo, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

// Components
import {
  U21Select,
  U21Section,
  U21Loading,
  U21NoData,
} from 'app/shared/u21-ui/components';

// Helpers
import { formatEntityOptions } from 'app/modules/search/helpers';

// Selectors
import { NetworkAnalysis } from 'app/modules/networkAnalysisRefresh/components/NetworkAnalysis';
import { useGetCase } from 'app/modules/cases/queries/useGetCase';
import { BaseObjectType } from 'app/modules/networkAnalysisRefresh/types';

export const NetworkAnalysisTab = ({ caseId }: { caseId: number }) => {
  const { case: caseData } = useGetCase(caseId);
  const entities = useMemo(() => caseData?.entities ?? [], [caseData]);
  const [selectedEntityId, setSelectedEntityId] = useState<
    number | string | undefined
  >(entities?.[0]?.id);

  const entityOptions = useMemo(
    () => formatEntityOptions(entities),
    [entities],
  );

  useEffect(() => {
    if (entities.length) {
      setSelectedEntityId(entities[0].id);
    }
  }, [entities]);

  const selectedEntity = useMemo(
    () => entities.find((e) => e.id === selectedEntityId),
    [entities, selectedEntityId],
  );

  if (!selectedEntityId) {
    return (
      <U21Section>
        <U21Loading loading variant="graphic" />
      </U21Section>
    );
  }

  if (!selectedEntity) {
    return <U21NoData />;
  }

  return (
    <>
      {entities.length > 1 && (
        <SelectContainer>
          <U21Select
            label="Network analysis for entity"
            clearable={false}
            onChange={(entityId: number | string) => {
              if (entityId) {
                setSelectedEntityId(entityId);
              }
            }}
            options={entityOptions}
            value={selectedEntityId}
          />
        </SelectContainer>
      )}
      <NetworkAnalysis
        baseObject={selectedEntity}
        baseObjectType={BaseObjectType.ENTITY}
        caseId={caseId}
      />
    </>
  );
};

const SelectContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing(2)};
  `};
`;
