import {
  IconBraces,
  IconCoin,
  IconCreditCard,
  IconFingerprint,
  IconHome,
  IconLicense,
  IconMail,
  IconNumber,
  IconPhone,
  IconUser,
  IconWifi,
} from '@u21/tabler-icons';
import {
  NodeType,
  EntityNetworkAnalysisFilters,
  ConnectionType,
  InstrumentNetworkAnalysisFilters,
  BaseObjectType,
} from 'app/modules/networkAnalysisRefresh/types';
import {
  Braces,
  CreditCard,
  Fingerprint,
  Home,
  License,
  Mail,
  Phone,
  Ssn,
  User,
  Wifi,
} from 'app/shared/components/Graphs/svgs';
import { ReactElement } from 'react-resizable-panels/dist/declarations/src/vendor/react';
import { U21ButtonGroupButtonProps } from 'app/shared/u21-ui/components';
import { NetworkAnalysisType } from 'app/modules/networkAnalysisSettings/types';
import { ConfiguredFlow } from 'app/modules/dataSettings/types';

export const NODE_OR_CONNECTION_TYPE_TO_READABLE_NAME: Record<
  NodeType | ConnectionType,
  string
> = {
  [NodeType.ADDRESS]: 'Address',
  [NodeType.CLIENT_FINGERPRINT]: 'Client Fingerprint',
  [NodeType.EMAIL_ADDRESS]: 'Email Address',
  [NodeType.INSTRUMENT]: 'Instrument',
  [NodeType.IP_ADDRESS]: 'IP Address',
  [NodeType.PHONE_NUMBER]: 'Phone',
  [NodeType.PHYSICAL_ID]: 'Physical ID',
  [NodeType.SSN]: 'SSN',
  [NodeType.ENTITY]: 'Entity',
  [NodeType.CUSTOM_FIELD]: 'Custom Field',
  [ConnectionType.ENTITY_RELATIONSHIP]: 'Entity Relationship',
  [ConnectionType.INSTRUMENT_RELATIONSHIP]: 'Instrument Relationship',
  [ConnectionType.ACCOUNT_NUMBER]: 'Account Number',
  [ConnectionType.TRANSACTION]: 'Transaction',
};

export const NODE_TYPE_TO_BG_IMG: Record<NodeType, string> = {
  [NodeType.ADDRESS]: Home,
  [NodeType.CLIENT_FINGERPRINT]: Fingerprint,
  [NodeType.EMAIL_ADDRESS]: Mail,
  [NodeType.ENTITY]: User,
  [NodeType.IP_ADDRESS]: Wifi,
  [NodeType.PHONE_NUMBER]: Phone,
  [NodeType.PHYSICAL_ID]: License,
  [NodeType.SSN]: Ssn,
  [NodeType.INSTRUMENT]: CreditCard,
  [NodeType.CUSTOM_FIELD]: Braces,
};

export const NODE_OR_CONNECTION_TYPE_TO_ICON: Record<
  NodeType | ConnectionType,
  ReactElement
> = {
  [NodeType.ADDRESS]: <IconHome />,
  [NodeType.CLIENT_FINGERPRINT]: <IconFingerprint />,
  [NodeType.EMAIL_ADDRESS]: <IconMail />,
  [NodeType.ENTITY]: <IconUser />,
  [NodeType.IP_ADDRESS]: <IconWifi />,
  [NodeType.PHONE_NUMBER]: <IconPhone />,
  [NodeType.PHYSICAL_ID]: <IconLicense />,
  [NodeType.SSN]: <IconNumber />,
  [NodeType.INSTRUMENT]: <IconCreditCard />,
  [NodeType.CUSTOM_FIELD]: <IconBraces />,
  [ConnectionType.ACCOUNT_NUMBER]: <IconCreditCard />,
  [ConnectionType.ENTITY_RELATIONSHIP]: <IconUser />,
  [ConnectionType.INSTRUMENT_RELATIONSHIP]: <IconCreditCard />,
  [ConnectionType.TRANSACTION]: <IconCoin />,
};

export const EMPTY_INSTRUMENT_FILTERS: InstrumentNetworkAnalysisFilters = {
  linkType: [],
  transactionFilters: { min: 0, direction: 'ANY' },
};

export const EMPTY_ENTITY_FILTERS: EntityNetworkAnalysisFilters = {
  ...EMPTY_INSTRUMENT_FILTERS,
  entityType: [],
  entitySubtype: [],
  entityStatus: [],
};

export const TRANSACTION_SENDING_RECEIVING_OPTIONS: U21ButtonGroupButtonProps<
  'ANY' | ConfiguredFlow
>[] = [
  { label: 'Any', value: 'ANY' as const },
  { label: 'Sending', value: 'OUTBOUND' as const },
  { label: 'Receiving', value: 'INBOUND' as const },
  { label: 'Other', value: 'OTHER' as const },
];

export const BASE_OBJECT_TYPE_TO_NETWORK_ANALYSIS_TYPE: Record<
  BaseObjectType,
  NetworkAnalysisType
> = {
  [BaseObjectType.ENTITY]: NetworkAnalysisType.ENTITY,
  [BaseObjectType.INSTRUMENT]: NetworkAnalysisType.INSTRUMENT,
};

export const LINK_SECTION_MOUNTED = 'linkSectionMounted';
export const LINK_SECTION_UNMOUNTED = 'linkSectionUnMounted';
export const TRANSACTIONS_SECTION_HASH = 'transactions' as const;
export const ENTITY_RELATIONSHIPS_SECTION_HASH = 'entityRelationships' as const;
export const INSTRUMENT_RELATIONSHIPS_SECTION_HASH =
  'instrumentRelationships' as const;

export const ELEMENT_KEYS_TO_UPDATE = {
  nodes: ['degree', 'opaque', 'selected'],
  edges: ['selected'],
};
