import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { required } from 'app/shared/utils/validation';

// Hooks
import { useFetchFraudLossTable } from 'app/modules/fraudDashboard/queries/useFetchFraudLossTable';
import { useEditFraudTxnMeta } from 'app/modules/fraudDashboard/queries/useEditFraudTxnMeta';

// Selectors
import { selectInvestigationCase } from 'app/modules/investigations/selectors/investigations';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';

// Models
import {
  GetFraudLossTablePayload,
  FraudLossFormState,
} from 'app/modules/fraudDashboard/models';
import { TxnMetadataPayload } from 'app/modules/fraudDashboard/requests';
import { FraudLossTableData } from 'app/modules/fraudDashboard/responses';

// Components
import { FraudLossTable } from 'app/modules/fraudDashboard/component/FraudLossTable';
import {
  U21Section,
  U21Spacer,
  U21Button,
  U21Table,
  U21TableColumn,
  U21Form,
  U21FormFieldArray,
  U21FormField,
  U21FormFieldTypes,
  U21Typography,
  U21TableBodyCellProps,
  U21Grid,
  U21CountLabel,
  U21LoadError,
  useU21Form,
  useU21FormState,
} from 'app/shared/u21-ui/components';
import { IconEdit } from '@u21/tabler-icons';

// Constants
import { DEFAULT_OFFSET } from 'app/shared/pagination/constants';
import {
  FRAUD_LOSS_VICTIM_OPTIONS,
  FRAUD_TYPE_OPTIONS,
  TXN_META_TABLE_COLUMNS,
  INITIAL_BATCH_UPDATES,
} from 'app/modules/fraudDashboard/constants';
import { DEFAULT_PAGE_SIZE } from 'app/shared/u21-ui/components/display/table/constants';

// Helpers
import { updateFraudLoss } from 'app/modules/fraudDashboard/helpers';

const FORM_ID = 'fraud-loss-form';

interface FraudLossEditableTableRow extends TxnMetadataPayload {
  // Row type inside the editable table, it has the form fieldPath
  fieldPath: string;
}

// Columns for the editable table, almost the same but the columns are enhanced with the form fieldPath
const COLUMNS = (
  TXN_META_TABLE_COLUMNS as any as U21TableColumn<FraudLossEditableTableRow>[]
).map((column) => {
  switch (column.id) {
    case 'fraud_victim':
      return {
        ...column,
        width: 150,
        Cell: ({
          row: { fieldPath },
        }: U21TableBodyCellProps<FraudLossEditableTableRow>) => (
          <SelectWrapper>
            <U21FormField
              required
              validate={required()}
              type={U21FormFieldTypes.SELECT}
              name={`${fieldPath}.fraud_victim`}
              fieldProps={{
                clearable: false,
                options: FRAUD_LOSS_VICTIM_OPTIONS,
              }}
            />
          </SelectWrapper>
        ),
      };
    case 'fraud_type':
      return {
        ...column,
        width: 200,
        Cell: ({
          row: { fieldPath },
        }: U21TableBodyCellProps<FraudLossEditableTableRow>) => (
          <SelectWrapper>
            <U21FormField
              required
              validate={required()}
              type={U21FormFieldTypes.SELECT}
              name={`${fieldPath}.fraud_type`}
              fieldProps={{
                clearable: false,
                options: FRAUD_TYPE_OPTIONS,
              }}
            />
          </SelectWrapper>
        ),
      };
    case 'prevented_loss':
      return {
        ...column,
        Cell: ({
          row: { fieldPath },
        }: U21TableBodyCellProps<FraudLossEditableTableRow>) => {
          return (
            <SelectWrapper>
              <U21FormField
                required
                validate={required()}
                type={U21FormFieldTypes.NUMBER}
                name={`${fieldPath}.prevented_loss`}
                fieldProps={{
                  clearable: false,
                }}
              />
            </SelectWrapper>
          );
        },
      };
    case 'recovered_loss':
      return {
        ...column,
        Cell: ({
          row: { fieldPath },
        }: U21TableBodyCellProps<FraudLossEditableTableRow>) => {
          return (
            <SelectWrapper>
              <U21FormField
                required
                validate={required()}
                type={U21FormFieldTypes.NUMBER}
                name={`${fieldPath}.recovered_loss`}
                fieldProps={{
                  clearable: false,
                }}
              />
            </SelectWrapper>
          );
        },
      };
    case 'actual_loss':
      return {
        ...column,
        Cell: ({
          row: { fieldPath },
        }: U21TableBodyCellProps<FraudLossEditableTableRow>) => {
          return (
            <SelectWrapper>
              <U21FormField
                required
                validate={required()}
                type={U21FormFieldTypes.NUMBER}
                name={`${fieldPath}.actual_loss`}
                fieldProps={{
                  clearable: false,
                }}
              />
            </SelectWrapper>
          );
        },
      };
    default:
      return column;
  }
});

const BatchUpdateInputs = () => {
  const {
    values: { rows, batchUpdates },
  } = useU21FormState<FraudLossFormState>();
  const {
    mutators: { setFieldValue },
  } = useU21Form<FraudLossFormState>();

  // Check that there's at least one batch value
  const canApplyBatchUpdates =
    batchUpdates && Object.values(batchUpdates).some((val) => Boolean(val));

  return (
    <U21Spacer>
      <U21Typography variant="h6">Labeling</U21Typography>
      <U21Grid>
        <div>
          <U21Typography variant="subtitle2">Fraud type</U21Typography>
          <U21Typography variant="body2" color="text.secondary">
            Label all transactions on this alert with this fraud type, or label
            them individually.
          </U21Typography>
        </div>
        <SelectWrapper>
          <U21FormField
            type={U21FormFieldTypes.SELECT}
            name="batchUpdates.fraudType"
            fieldProps={{
              options: FRAUD_TYPE_OPTIONS,
              placeholder: 'Select fraud type',
            }}
          />
        </SelectWrapper>
        <div>
          <U21Typography variant="subtitle2">Loss by</U21Typography>
          <U21Typography variant="body2" color="text.secondary">
            Select who experienced the fraud loss.
          </U21Typography>
        </div>
        <SelectWrapper>
          <U21FormField
            type={U21FormFieldTypes.SELECT}
            name="batchUpdates.fraudVictim"
            fieldProps={{
              options: FRAUD_LOSS_VICTIM_OPTIONS,
              placeholder: 'Select loss by',
            }}
          />
        </SelectWrapper>
      </U21Grid>
      <ApplyToAllButton
        color="primary"
        disabled={!canApplyBatchUpdates}
        onClick={() => {
          // Update rows
          const newRows = rows.map((row) => updateFraudLoss(row, batchUpdates));
          setFieldValue('rows', newRows);

          // Reset the batch fraud options
          setFieldValue('batchUpdates', INITIAL_BATCH_UPDATES);
        }}
      >
        Apply to all
      </ApplyToAllButton>
    </U21Spacer>
  );
};

export const CaseFraudLossTab = () => {
  const { id, events } = useSelector(selectInvestigationCase);
  const [editMode, setEditMode] = useState<boolean>(false);
  const { mutateAsync: editAsync, isPending: editLoading } =
    useEditFraudTxnMeta();
  // Fix permissioning, for now we allow to update the losses if they can read a transaction
  const canEditFraudLosses = useSelector(selectHasReadEventsPermission);

  // Get the fraud loss data we have already stored
  const fraudLossPayload: GetFraudLossTablePayload = useMemo(
    () => ({
      offset: DEFAULT_OFFSET,
      event_external_ids: events.map(
        ({ external_id: externalId }) => externalId,
      ),
      limit: Math.max(DEFAULT_PAGE_SIZE, events.length),
      article_ids: [id],
      sort_column: 'event_time',
      sort_direction: 'descending',
    }),
    [events, id],
  );

  const {
    data: tableData,
    isLoading: tableLoading,
    isError: isTableDataError,
    refetch: refetchTableData,
    isRefetching: isTableDataRefetching,
  } = useFetchFraudLossTable(fraudLossPayload, { enabled: id > 0 });

  // Create a map of stored metadata and the transactions the case has and use it as the initial state of the form
  const fraudLossInitialState: FraudLossFormState = useMemo(() => {
    const fraudLossMeta: Record<string, FraudLossTableData> = (
      tableData?.results ?? []
    ).reduce(
      (acc, { event_external_id: externalId, ...rest }) => ({
        ...acc,
        [externalId]: rest,
      }),
      {},
    );
    const fraudLossRows: TxnMetadataPayload[] = events.map(
      ({ external_id: externalId, amount, event_time: eventTime }) => {
        const txnMeta: undefined | FraudLossTableData =
          fraudLossMeta[externalId];
        const txnAmount = amount || 0;
        return {
          event_external_id: externalId,
          article_id: id,
          event_time: eventTime || txnMeta?.event_time || '',
          fraud_type: txnMeta?.fraud_type || 'CHARGE_BACK',
          fraud_victim: txnMeta?.fraud_victim || 'INSTITUTION',
          potential_loss: txnMeta?.potential_loss || txnAmount,
          prevented_loss: txnMeta?.prevented_loss || 0,
          actual_loss: txnMeta?.actual_loss || 0,
          recovered_loss: txnMeta?.recovered_loss || 0,
        } satisfies TxnMetadataPayload;
      },
    );

    return {
      rows: fraudLossRows,
      batchUpdates: INITIAL_BATCH_UPDATES,
    };
  }, [events, tableData, id]);

  const renderEditableForm = () => {
    return (
      <U21Form<FraudLossFormState>
        id={FORM_ID}
        initialValues={fraudLossInitialState}
        onSubmit={async ({ rows }) => {
          try {
            await editAsync({ txn_metadata: rows });
            setEditMode(false);
          } catch {}
        }}
      >
        <U21Spacer>
          <U21FormFieldArray name="rows">
            {(props: FieldArrayRenderProps<any, HTMLElement>) => {
              const { fields } = props;
              const { value } = fields;
              const tableRows: FraudLossEditableTableRow[] = fields.map(
                (field, ix) => ({
                  ...value[ix],
                  fieldPath: field,
                }),
              );

              return (
                <U21Spacer spacing={2}>
                  <BatchUpdateInputs />
                  <U21Spacer horizontal>
                    <U21Typography variant="subtitle2">
                      Associated transactions
                    </U21Typography>
                    <U21CountLabel
                      count={tableRows.length}
                      label="transaction"
                    />
                  </U21Spacer>
                  {isTableDataError ? (
                    <U21LoadError
                      label="associated transactions"
                      onTryAgain={() => refetchTableData()}
                    />
                  ) : (
                    <U21Table
                      columns={COLUMNS}
                      count={tableRows.length}
                      data={tableRows}
                      getRowID={({ event_external_id: externalId }) =>
                        externalId
                      }
                      loading={tableLoading}
                      onRefresh={() => refetchTableData()}
                      refreshLoading={isTableDataRefetching}
                      title="Associated Transactions"
                    />
                  )}
                </U21Spacer>
              );
            }}
          </U21FormFieldArray>
        </U21Spacer>
      </U21Form>
    );
  };

  const renderDetailsTable = () => {
    if (id > 0) {
      return (
        <FraudLossTable
          payload={fraudLossPayload}
          noDataComponent={
            events?.length
              ? 'There is nothing to show here...'
              : 'Add transactions to this case to update fraud loss.'
          }
        />
      );
    }
    return null;
  };

  const renderDetailsActions = () => {
    if (!canEditFraudLosses) {
      return null;
    }

    return (
      <U21Button
        onClick={() => setEditMode(true)}
        variant="contained"
        color="primary"
        startIcon={<IconEdit />}
        disabled={!events?.length}
      >
        Update Losses
      </U21Button>
    );
  };

  const renderEditModeActions = () => {
    if (!canEditFraudLosses) {
      return null;
    }

    return (
      <U21Spacer horizontal>
        <U21Button onClick={() => setEditMode(false)}>Cancel</U21Button>
        <U21Button
          type="submit"
          form={FORM_ID}
          loading={editLoading}
          variant="contained"
          color="primary"
        >
          Save
        </U21Button>
      </U21Spacer>
    );
  };

  return (
    <U21Spacer>
      <U21Section
        action={editMode ? renderEditModeActions() : renderDetailsActions()}
        title="Fraud Loss"
      >
        {editMode ? renderEditableForm() : renderDetailsTable()}
      </U21Section>
    </U21Spacer>
  );
};

const SelectWrapper = styled(U21Spacer)`
  width: 100%;
`;

const ApplyToAllButton = styled(U21Button)`
  align-self: flex-end;
`;
