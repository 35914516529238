import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import { useSelector } from 'react-redux';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';
import { ShortTxnInstrumentResponse } from 'app/modules/txnInstruments/types';
import { PaginationPayload } from 'app/shared/pagination/models';

export const useGetAlertInstruments = (
  id: number,
  payload: PaginationPayload,
) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  return useQuery({
    queryKey: ALERT_QUERY_KEYS.getAlertInstruments(id, payload),
    queryFn: () =>
      post<{ count: number | null; instruments: ShortTxnInstrumentResponse[] }>(
        `/alerts/${id}/instruments`,
        payload,
      ),
    enabled: hasReadAlertsPermission && payload.limit !== 0 && id > 0,
  });
};
