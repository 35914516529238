import { AiFindingsPopper } from 'app/shared/customChecklist/components/AiFindingsPopper';
import {
  AiInvestigationResultItem,
  AiInvestigationResultSection,
  AiInvestigationResultSectionItem,
} from 'app/modules/investigations/types/responses';
import { get } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { CustomChecklistDefinition } from 'app/shared/customChecklist/models';

interface Props {
  aiInvestigationResultItems: AiInvestigationResultSection | undefined;
  disabled: boolean;
  isOpenKey: string;
  itemKey: string;
  label?: string;
  setFieldValue: (key: string, value: any) => void;
  setIsOpenKey: Dispatch<SetStateAction<string>>;
  type: CustomChecklistDefinition['type'];
}

export const CustomChecklistAIFindings = (props: Props) => {
  const {
    aiInvestigationResultItems,
    disabled,
    isOpenKey,
    itemKey,
    label,
    setFieldValue,
    setIsOpenKey,
    type,
  } = props;
  const resultItem:
    | AiInvestigationResultSectionItem
    | AiInvestigationResultItem
    | undefined = get(aiInvestigationResultItems, itemKey);

  if (
    !aiInvestigationResultItems ||
    typeof resultItem?.result !== 'string' ||
    typeof resultItem?.system_output !== 'string'
  ) {
    return null;
  }

  const readableOutput: string = resultItem.result;
  const systemOutput: string = resultItem.system_output;

  switch (type) {
    case 'checkbox':
      return (
        <AiFindingsPopper
          readableOutput={readableOutput}
          itemKey={itemKey}
          isOpenKey={isOpenKey}
          label={label}
          setIsOpenKey={setIsOpenKey}
          actionLabel="Mark complete"
          onClick={() => {
            setFieldValue(itemKey, true);
            setIsOpenKey('');
          }}
          disabled={disabled}
        />
      );
    case 'textarea':
    case 'input':
      return (
        <AiFindingsPopper
          readableOutput={readableOutput}
          itemKey={itemKey}
          isOpenKey={isOpenKey}
          label={label}
          setIsOpenKey={setIsOpenKey}
          actionLabel="Use as input"
          onClick={() => {
            setFieldValue(itemKey, readableOutput);
            setIsOpenKey('');
          }}
          disabled={disabled}
        />
      );

    case 'dropdown':
      return (
        <AiFindingsPopper
          readableOutput={readableOutput}
          itemKey={itemKey}
          isOpenKey={isOpenKey}
          label={label}
          setIsOpenKey={setIsOpenKey}
          actionLabel="Use selection"
          onClick={() => {
            setFieldValue(itemKey, systemOutput);
            setIsOpenKey('');
          }}
          disabled={disabled}
        />
      );
    case 'multi_select':
      return (
        <AiFindingsPopper
          readableOutput={readableOutput}
          itemKey={itemKey}
          isOpenKey={isOpenKey}
          label={label}
          setIsOpenKey={setIsOpenKey}
          actionLabel="Use selection"
          onClick={() => {
            // The checklist select option keys convert `,` to `_` so comma-separated keys are safe to use
            setFieldValue(
              itemKey,
              systemOutput.split(',').map((x) => x.trim()),
            );
            setIsOpenKey('');
          }}
          disabled={disabled}
        />
      );
    default:
      return null;
  }
};
