import { createSelector } from 'reselect';
import { GaFeatureTypes } from 'app/shared/components/GaBanner/constants';
import { name as gaBannerSliceName } from 'app/shared/components/GaBanner/sliceGaBanner';
import {
  selectFeatureFlags,
  selectSideBySideEnabled,
} from 'app/shared/featureFlags/selectors';
import { FeatureFlag } from 'app/shared/featureFlags/models';
import { differenceInDays } from 'date-fns';

export const selectGaBannerSlice = (state: RootState) => {
  return state[gaBannerSliceName];
};

export const selectGaBannerHidden = createSelector(
  [
    selectFeatureFlags,
    selectGaBannerSlice,
    (_, featureName: GaFeatureTypes, gaDateFeatureFlag: FeatureFlag) => [
      featureName,
      gaDateFeatureFlag,
    ],
  ],
  (featureFlags, gaBannerSlice, [featureName, gaDateFeatureFlag]) => {
    const gaDate: string | undefined = featureFlags[gaDateFeatureFlag];
    if (gaDate) {
      return gaBannerSlice[featureName].gaBannerHidden;
    }
    return true;
  },
);

export const selectPreReleaseBannerHidden = createSelector(
  [
    selectFeatureFlags,
    selectGaBannerSlice,
    (_, featureName: GaFeatureTypes, preReleaseFeatureFlag: FeatureFlag) => [
      featureName,
      preReleaseFeatureFlag,
    ],
  ],
  (featureFlags, gaBannerSlice, [featureName, preReleaseFeatureFlag]) => {
    const preRelease: boolean | undefined = featureFlags[preReleaseFeatureFlag];
    if (preRelease) {
      return gaBannerSlice[featureName].preReleaseBannerHidden;
    }

    return true;
  },
);

export const selectNewFeatureEnabled = createSelector(
  [
    selectFeatureFlags,
    selectGaBannerSlice,
    (
      _,
      featureName: GaFeatureTypes,
      newUIFeatureFlag: FeatureFlag,
      gaDateFeatureFlag: FeatureFlag,
    ) => [featureName, newUIFeatureFlag, gaDateFeatureFlag],
  ],
  (
    featureFlags,
    gaBannerSlice,
    [featureName, newUIFeatureFlag, gaDateFeatureFlag],
  ) => {
    const gaDate: string | undefined = featureFlags[gaDateFeatureFlag];
    if (gaDate) {
      return (
        featureFlags[newUIFeatureFlag] &&
        !gaBannerSlice[featureName].featureDisabled
      );
    }
    return featureFlags[newUIFeatureFlag];
  },
);

export const selectEarlyAccessEnabled = createSelector(
  [
    selectFeatureFlags,
    selectGaBannerSlice,
    (_, featureName: GaFeatureTypes, earlyAccessFeatureFlag: FeatureFlag) => [
      featureName,
      earlyAccessFeatureFlag,
    ],
  ],
  (featureFlags, gaBannerSlice, [featureName, earlyAccessFeatureFlag]) => {
    if (featureFlags[earlyAccessFeatureFlag]) {
      return gaBannerSlice[featureName].earlyAccess;
    }
    return false;
  },
);

export const selectEarlyAccessOptInHidden = createSelector(
  [
    selectFeatureFlags,
    selectGaBannerSlice,
    (_, featureName: GaFeatureTypes, earlyAccessFeatureFlag: FeatureFlag) => [
      featureName,
      earlyAccessFeatureFlag,
    ],
  ],
  (featureFlags, gaBannerSlice, [featureName, earlyAccessFeatureFlag]) => {
    if (featureFlags[earlyAccessFeatureFlag]) {
      return (
        differenceInDays(
          new Date(),
          gaBannerSlice[featureName].earlyAccessOptInHidden,
        ) < 14
      );
    }
    return false;
  },
);

export const selectEarlyAccessOptOutHidden = createSelector(
  [
    selectFeatureFlags,
    selectGaBannerSlice,
    (_, featureName: GaFeatureTypes, earlyAccessFeatureFlag: FeatureFlag) => [
      featureName,
      earlyAccessFeatureFlag,
    ],
  ],
  (featureFlags, gaBannerSlice, [featureName, earlyAccessFeatureFlag]) => {
    if (featureFlags[earlyAccessFeatureFlag]) {
      return gaBannerSlice[featureName].earlyAccessOptOutHidden;
    }
    return false;
  },
);

export const selectNewUIFeatureFlag = createSelector(
  [selectFeatureFlags, (_, newUIFeatureFlag: FeatureFlag) => newUIFeatureFlag],
  (featureFlags, newUIFeatureFlag) => featureFlags[newUIFeatureFlag],
);

export const selectNewFeatureGADate = createSelector(
  [
    selectFeatureFlags,
    (_, gaDateFeatureFlag: FeatureFlag) => gaDateFeatureFlag,
  ],
  (featureFlagsSlice, gaDateFeatureFlag): string | undefined => {
    return featureFlagsSlice[gaDateFeatureFlag];
  },
);

export const selectPreReleaseFeatureFlag = createSelector(
  [
    selectFeatureFlags,
    (_, preReleaseFeatureFlag: FeatureFlag) => preReleaseFeatureFlag,
  ],
  (featureFlagsSlice, preReleaseFeatureFlag): boolean | undefined => {
    return featureFlagsSlice[preReleaseFeatureFlag];
  },
);

export const selectSideBySideAlertEarlyAccessEnabled = (
  state: RootState,
): boolean =>
  selectEarlyAccessEnabled(
    state,
    'ALERT_SIDE_BY_SIDE',
    FeatureFlag.SIDE_BY_SIDE_INVESTIGATIONS_EARLY_ACCESS,
  );

export const selectSideBySideAlertRegularAccessEnabled = (
  state: RootState,
): boolean =>
  selectNewFeatureEnabled(
    state,
    'ALERT_SIDE_BY_SIDE',
    FeatureFlag.SIDE_BY_SIDE_INVESTIGATIONS,
    FeatureFlag.SIDE_BY_SIDE_INVESTIGATIONS_GA,
  );

export const selectSideBySideAlertEnabled = createSelector(
  selectSideBySideAlertEarlyAccessEnabled,
  selectSideBySideAlertRegularAccessEnabled,
  selectSideBySideEnabled,
  (earlyAccess, regularAccess, sideBySideEnabled) => {
    if (sideBySideEnabled) {
      return regularAccess;
    }
    return earlyAccess;
  },
);

export const selectSideBySideCaseEarlyAccessEnabled = (
  state: RootState,
): boolean =>
  selectEarlyAccessEnabled(
    state,
    'CASE_SIDE_BY_SIDE',
    FeatureFlag.SIDE_BY_SIDE_INVESTIGATIONS_EARLY_ACCESS,
  );

export const selectSideBySideCaseRegularAccessEnabled = (
  state: RootState,
): boolean =>
  selectNewFeatureEnabled(
    state,
    'CASE_SIDE_BY_SIDE',
    FeatureFlag.SIDE_BY_SIDE_INVESTIGATIONS,
    FeatureFlag.SIDE_BY_SIDE_INVESTIGATIONS_GA,
  );

export const selectSideBySideCaseEnabled = createSelector(
  selectSideBySideCaseEarlyAccessEnabled,
  selectSideBySideCaseRegularAccessEnabled,
  selectSideBySideEnabled,
  (earlyAccess, regularAccess, sideBySideEnabled) => {
    if (sideBySideEnabled) {
      return regularAccess;
    }
    return earlyAccess;
  },
);
