import { ShortAlertResponse } from 'app/modules/alerts/types/responses';

import { INSTRUMENT_QUERY_KEYS } from 'app/modules/instruments/queries/keys';

import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { PaginationPayload } from 'app/shared/pagination/models';

export const useInstrumentAlerts = (
  instrumentExternalId: string,
  payload: PaginationPayload,
  enabled?: boolean,
) => {
  return useQuery({
    queryFn: () =>
      post<{ count: number; alerts: ShortAlertResponse[] }>(
        `/instruments/${instrumentExternalId}/paginated-alerts`,
        payload,
      ),
    queryKey: INSTRUMENT_QUERY_KEYS.getInstrumentAlerts({
      instrumentExternalId,
      ...payload,
    }),
    enabled: !!instrumentExternalId && enabled,
  });
};
