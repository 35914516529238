import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useReducer,
  Dispatch,
  SetStateAction,
} from 'react';
import { useFormatAmountWithDataSettingsPrecision } from 'app/modules/dataSettings/hooks/useFormatAmountWithDataSettingsPrecision';
import { TxnEventNativeFields } from 'app/modules/dataSettings/responses';
import { U21NetworkGraphProps } from 'app/shared/components/Graphs/U21NetworkGraph';
import { useGetNetworkAnalysis } from 'app/modules/networkAnalysisRefresh/queries/useGetNetworkAnalysis';
import {
  BaseObjectType,
  DivRefObject,
  EntityNetworkAnalysisFilters,
  FilteredNetworkAnalysisData,
  InstrumentNetworkAnalysisFilters,
  LinkSectionMounted,
  LinkSectionUnMounted,
  NetworkAnalysisGroups,
  NetworkAnalysisRefreshResponse,
  NodeType,
  ObjectCounts,
} from 'app/modules/networkAnalysisRefresh/types';
import {
  convertDataToNetworkAnalysisGroups,
  filterData,
  getElements,
  getObjectCounts,
} from 'app/modules/networkAnalysisRefresh/helpers';
import {
  EMPTY_ENTITY_FILTERS,
  EMPTY_INSTRUMENT_FILTERS,
  LINK_SECTION_MOUNTED,
  LINK_SECTION_UNMOUNTED,
} from 'app/modules/networkAnalysisRefresh/constants';
import {
  useGetNetworkAnalysisEntities,
  useGetNetworkAnalysisInstruments,
} from 'app/modules/networkAnalysisRefresh/queries/useGetNetworkAnalysisObjects';
import { GetEntitiesResponse } from 'app/modules/entitiesRefresh/responses';
import { GetInstrumentsResponse } from 'app/modules/instruments/responses';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { selectDataSettingsById } from 'app/modules/dataSettings/selectors';

const EMPTY_FILTERED_DATA: FilteredNetworkAnalysisData = {
  objects: new Set(),
  links: new Set(),
  transactions: new Set(),
  entityRelationships: new Set(),
  instrumentRelationships: new Set(),
};

const EMPTY_ELEMENTS: U21NetworkGraphProps['elements'] = {
  nodes: {},
  edges: {},
};

const EMPTY_NETWORK_GROUPS: NetworkAnalysisGroups = {
  links: {},
  objects: {},
  transactions: {},
  entityRelationships: {},
  instrumentRelationships: {},
};

export interface NetworkAnalysisContextState<
  T extends BaseObjectType = BaseObjectType,
> {
  externalId: string;
  baseObjectType: T;
  analysisIsPending: boolean;
  setAnalysisIsPending: React.Dispatch<React.SetStateAction<boolean>>;
  data?: NetworkAnalysisRefreshResponse;
  isLoading: boolean;
  filteredData: FilteredNetworkAnalysisData;
  elements: U21NetworkGraphProps['elements'];
  networkGroups: NetworkAnalysisGroups;
  totals: ObjectCounts;
  expandedSections: Set<string>;
  setExpandedSections: React.Dispatch<React.SetStateAction<Set<string>>>;
  getToggleExpandedSection: (
    sectionHash: string,
  ) => (collapsed: boolean) => void;
  hashReferences: Record<string, DivRefObject>;
  dispatch: React.Dispatch<
    | {
        type: LinkSectionMounted;
        payload: Record<string, DivRefObject>;
      }
    | {
        type: LinkSectionUnMounted;
        payload: { sectionHash: string };
      }
  >;
  handleLinkSectionMounted: (linkId: string, sectionRef: DivRefObject) => void;
  filters: T extends BaseObjectType.ENTITY
    ? EntityNetworkAnalysisFilters
    : InstrumentNetworkAnalysisFilters;
  setFilters: T extends BaseObjectType.ENTITY
    ? Dispatch<SetStateAction<EntityNetworkAnalysisFilters>>
    : Dispatch<SetStateAction<InstrumentNetworkAnalysisFilters>>;
  clearFilters: () => void;
  noFiltersApplied: boolean;
  entityExternalIds: string[];
  instrumentExternalIds: string[];
  fetchedEntities: Record<string, GetEntitiesResponse['entities'][number]>;
  isFetchingEntities: boolean;
  fetchedInstruments: Record<
    string,
    GetInstrumentsResponse['txn_instruments'][number]
  >;
  isFetchingInstruments: boolean;
  caseObjects: string[];
  setCaseObjects: Dispatch<SetStateAction<string[]>>;
  caseId?: number;
}

const NetworkAnalysisContext = createContext<
  NetworkAnalysisContextState<BaseObjectType> | undefined
>(undefined);

interface NetworkAnalysisProviderProps {
  children: React.ReactNode;
  externalId: string;
  baseObjectType: BaseObjectType;
  caseId?: number;
}

export const NetworkAnalysisProvider = ({
  children,
  externalId,
  baseObjectType,
  caseId,
}: NetworkAnalysisProviderProps) => {
  const { data, isLoading } = useGetNetworkAnalysis(externalId, baseObjectType);
  const [analysisIsPending, setAnalysisIsPending] = useState(false);
  const {
    instrumentExternalIds,
    entityExternalIds,
  }: {
    instrumentExternalIds: string[];
    entityExternalIds: string[];
  } = useMemo(
    () =>
      !data?.graph_result?.nodes
        ? { instrumentExternalIds: [], entityExternalIds: [] }
        : Object.values(data.graph_result.nodes).reduce<{
            instrumentExternalIds: string[];
            entityExternalIds: string[];
          }>(
            (acc, node) => {
              if (node.node_type === NodeType.ENTITY) {
                acc.entityExternalIds.push(node.external_id);
              } else if (node.node_type === NodeType.INSTRUMENT) {
                acc.instrumentExternalIds.push(node.external_id);
              }
              return acc;
            },
            { instrumentExternalIds: [], entityExternalIds: [] },
          ),
    [data],
  );

  const { data: fetchedEntitiesResponse, isLoading: isFetchingEntities } =
    useGetNetworkAnalysisEntities(entityExternalIds);
  const fetchedEntities = useMemo(() => {
    if (!fetchedEntitiesResponse?.entities.length) {
      return {};
    }
    return fetchedEntitiesResponse.entities.reduce<
      Record<string, GetEntitiesResponse['entities'][number]>
    >((acc, entity) => {
      acc[entity.external_id] = entity;
      return acc;
    }, {});
  }, [fetchedEntitiesResponse]);

  const { data: fetchedInstrumentsResponse, isLoading: isFetchingInstruments } =
    useGetNetworkAnalysisInstruments(instrumentExternalIds);
  const fetchedInstruments = useMemo(() => {
    if (!fetchedInstrumentsResponse?.txn_instruments.length) {
      return {};
    }
    return fetchedInstrumentsResponse.txn_instruments.reduce<
      Record<string, GetInstrumentsResponse['txn_instruments'][number]>
    >((acc, instrument) => {
      acc[instrument.external_id] = instrument;
      return acc;
    }, {});
  }, [fetchedInstrumentsResponse]);

  const [entityFilters, setEntityFilters] =
    useState<EntityNetworkAnalysisFilters>(EMPTY_ENTITY_FILTERS);
  const [instrumentFilters, setInstrumentFilters] =
    useState<InstrumentNetworkAnalysisFilters>(EMPTY_INSTRUMENT_FILTERS);

  const formatAmount = useFormatAmountWithDataSettingsPrecision(
    TxnEventNativeFields.AMOUNT,
  );

  const filteredData = useMemo(() => {
    if (!data) {
      return EMPTY_FILTERED_DATA;
    }
    return baseObjectType === BaseObjectType.ENTITY
      ? filterData(data.graph_result, {
          filters: entityFilters,
          baseObjectType,
        })
      : filterData(data.graph_result, {
          filters: instrumentFilters,
          baseObjectType,
        });
  }, [data, entityFilters, instrumentFilters, baseObjectType]);

  const dataSettingsById = useSelector(selectDataSettingsById);

  const elements = useMemo(() => {
    if (!data) {
      return EMPTY_ELEMENTS;
    }
    return getElements(
      data.graph_result,
      baseObjectType,
      filteredData,
      formatAmount,
      dataSettingsById,
    );
  }, [data, baseObjectType, filteredData, formatAmount, dataSettingsById]);

  const networkGroups = useMemo(() => {
    if (!data) {
      return EMPTY_NETWORK_GROUPS;
    }
    return convertDataToNetworkAnalysisGroups(
      data.graph_result,
      filteredData,
      baseObjectType,
    );
  }, [data, filteredData, baseObjectType]);

  const totals = useMemo(
    () => getObjectCounts(baseObjectType, data?.graph_result),
    [data, baseObjectType],
  );

  // shared vars needed for scrolling to link tables

  const [expandedSections, setExpandedSections] = useState<Set<string>>(
    new Set(),
  );

  const getToggleExpandedSection = useCallback(
    (sectionHash: string) => {
      return (collapsed: boolean) => {
        setExpandedSections((prev) => {
          const newSet = new Set(prev);
          if (collapsed) {
            newSet.delete(sectionHash);
          } else {
            newSet.add(sectionHash);
          }
          return newSet;
        });
      };
    },
    [setExpandedSections],
  );

  /**
   * hashReferences: Map form URL hash -> DOM node
   * Not in redux because DOM node is not serializable
   */
  const [hashReferences, dispatch] = useReducer(
    (
      state: Record<string, DivRefObject>,
      action:
        | {
            type: LinkSectionMounted;
            payload: Record<string, DivRefObject>;
          }
        | {
            type: LinkSectionUnMounted;
            payload: { sectionHash: string };
          },
    ) => {
      if (action.type === LINK_SECTION_MOUNTED) {
        return { ...state, ...action.payload };
      }
      if (action.type === LINK_SECTION_UNMOUNTED) {
        const newState = { ...state };
        delete newState[action.payload.sectionHash];
        return newState;
      }
      return state;
    },
    {},
  );

  const handleLinkSectionMounted = useCallback(
    (linkId: string, sectionRef: DivRefObject) => {
      dispatch({
        type: LINK_SECTION_MOUNTED,
        payload: { [linkId]: sectionRef },
      });
    },
    [dispatch],
  );

  const clearFilters = useCallback(
    () =>
      baseObjectType === BaseObjectType.ENTITY
        ? setEntityFilters(EMPTY_ENTITY_FILTERS)
        : setInstrumentFilters(EMPTY_INSTRUMENT_FILTERS),
    [baseObjectType, setEntityFilters, setInstrumentFilters],
  );

  const noFiltersApplied = useMemo(
    () =>
      isEqual(
        baseObjectType === BaseObjectType.ENTITY
          ? entityFilters
          : instrumentFilters,
        EMPTY_ENTITY_FILTERS,
      ),
    [entityFilters, instrumentFilters, baseObjectType],
  );

  const [caseObjects, setCaseObjects] = useState<string[]>([]);

  const contextValue: NetworkAnalysisContextState<BaseObjectType> = useMemo(
    () => ({
      externalId,
      baseObjectType,
      data,
      isLoading,
      analysisIsPending,
      setAnalysisIsPending,
      filteredData,
      elements,
      networkGroups,
      totals,
      expandedSections,
      setExpandedSections,
      getToggleExpandedSection,
      hashReferences,
      dispatch,
      handleLinkSectionMounted,
      filters:
        baseObjectType === BaseObjectType.ENTITY
          ? entityFilters
          : instrumentFilters,
      setFilters:
        baseObjectType === BaseObjectType.ENTITY
          ? setEntityFilters
          : setInstrumentFilters,
      clearFilters,
      noFiltersApplied,
      entityExternalIds,
      instrumentExternalIds,
      fetchedEntities,
      isFetchingEntities,
      fetchedInstruments,
      isFetchingInstruments,
      caseObjects,
      setCaseObjects,
      caseId,
    }),
    [
      externalId,
      baseObjectType,
      data,
      isLoading,
      analysisIsPending,
      filteredData,
      elements,
      networkGroups,
      totals,
      expandedSections,
      getToggleExpandedSection,
      hashReferences,
      handleLinkSectionMounted,
      entityFilters,
      instrumentFilters,
      clearFilters,
      noFiltersApplied,
      entityExternalIds,
      instrumentExternalIds,
      fetchedEntities,
      isFetchingEntities,
      fetchedInstruments,
      isFetchingInstruments,
      caseObjects,
      caseId,
    ],
  );

  return (
    <NetworkAnalysisContext.Provider value={contextValue}>
      {children}
    </NetworkAnalysisContext.Provider>
  );
};

export const useNetworkAnalysisContext = () => {
  const context = useContext(NetworkAnalysisContext);
  if (!context) {
    throw new Error(
      'useNetworkAnalysis must be used within a NetworkAnalysisProvider',
    );
  }
  return context;
};
