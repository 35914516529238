import { AiAdminSidebarDefinition } from 'app/modules/sidebar/models';
import {
  U21CopyButton,
  U21TextField,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';
import { TableConfig } from 'app/shared/pagination/models';
import styled from 'styled-components';
import React from 'react';
import { AI_ADMIN_INFO_MAP } from 'app/modules/aiAdmin/constants';

interface OwnProps {
  data: AiAdminSidebarDefinition['data'];
}

export const SidebarAiAdmin = ({ data }: OwnProps) => {
  return (
    <U21SideMenu
      title={AI_ADMIN_INFO_MAP[data.contentType].title}
      titleIcon={AI_ADMIN_INFO_MAP[data.contentType].icon}
    >
      {data.contentHeader.map((header: TableConfig) => {
        const value =
          typeof data.content[header.key] === 'object'
            ? JSON.stringify(data.content[header.key])
            : data.content[header.key];

        return (
          <div key={header.key}>
            <StyledTitleBar>
              <U21Typography variant="subtitle2">{header.label}</U21Typography>
              <U21CopyButton text={value} />
            </StyledTitleBar>
            <U21TextField
              type="textarea"
              disabled
              onChange={() => {}}
              value={value}
            />
          </div>
        );
      })}
    </U21SideMenu>
  );
};

const StyledTitleBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  margin-top: 8px;
`;
