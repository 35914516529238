import {
  U21Badge,
  U21Section,
  U21Table,
  U21TableColumn,
} from 'app/shared/u21-ui/components';
import { useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  BaseObjectType,
  MergedEntity,
  MergedInstrument,
  NetworkAnalysisGroups,
} from 'app/modules/networkAnalysisRefresh/types';
import { SelectFormatAmountPayload } from 'app/modules/orgSettings/models';
import { useFormatAmountWithDataSettingsPrecision } from 'app/modules/dataSettings/hooks/useFormatAmountWithDataSettingsPrecision';
import palette from 'vendor/material-minimal/palette';
import {
  NetworkAnalysisContextState,
  useNetworkAnalysisContext,
} from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';
import { getLinksColumn } from 'app/modules/networkAnalysisRefresh/helpers';
import { createTableColumnConfig } from 'app/shared/utils/table';
import { ENTITY_COLUMN_CONFIG } from 'app/modules/entitiesRefresh/columns';
import { INSTRUMENT_COLUMN_CONFIG } from 'app/modules/instruments/columns';
import {
  selectEntityTableConfig,
  selectInstrumentTableConfig,
} from 'app/shared/CustomConfig/selectors';
import { LinkSectionActions } from 'app/modules/networkAnalysisRefresh/components/LinkSection';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { TRANSACTIONS_SECTION_HASH } from 'app/modules/networkAnalysisRefresh/constants';

interface BaseTransactionLinkTableRow {
  amount: string;
  amountColor: string;
  rowId: string;
}

export type EntityTransactionLinkTableRow = BaseTransactionLinkTableRow &
  MergedEntity;

export type InstrumentTransactionLinkTableRow = BaseTransactionLinkTableRow &
  MergedInstrument;

export const TransactionsSection = () => {
  const {
    data,
    networkGroups,
    expandedSections,
    getToggleExpandedSection,
    handleLinkSectionMounted,
    baseObjectType,
    fetchedEntities,
    fetchedInstruments,
  } = useNetworkAnalysisContext();

  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return handleLinkSectionMounted(TRANSACTIONS_SECTION_HASH, sectionRef);
  }, [handleLinkSectionMounted]);

  const { pathname, search } = useLocation();
  const history = useHistory();

  const linkedTransactionsLength = useMemo(
    () => Object.keys(networkGroups.transactions).length,
    [networkGroups.transactions],
  );

  const formatAmount = useFormatAmountWithDataSettingsPrecision();

  const { entityTableData, instrumentTableData } = useMemo(
    () => ({
      entityTableData:
        baseObjectType === BaseObjectType.ENTITY
          ? makeTransactionsLinksTableData(
              fetchedEntities,
              networkGroups,
              formatAmount,
            )
          : [],
      instrumentTableData:
        baseObjectType === BaseObjectType.INSTRUMENT
          ? makeTransactionsLinksTableData(
              fetchedInstruments,
              networkGroups,
              formatAmount,
            )
          : [],
    }),
    [
      baseObjectType,
      fetchedEntities,
      networkGroups,
      formatAmount,
      fetchedInstruments,
    ],
  );

  const reduxDispatch = useDispatch();
  const entityTableConfig = useSelector(selectEntityTableConfig);

  const entityColumns: U21TableColumn<EntityTransactionLinkTableRow>[] =
    useMemo(() => {
      if (baseObjectType === BaseObjectType.ENTITY && data) {
        return [
          getAmountColumn<EntityTransactionLinkTableRow>(),
          getLinksColumn<EntityTransactionLinkTableRow>(
            data?.graph_result?.nodes,
            networkGroups,
            history,
            pathname,
            search,
          ),
          ...createTableColumnConfig<EntityTransactionLinkTableRow>(
            entityTableConfig,
            ENTITY_COLUMN_CONFIG as Record<
              string,
              Partial<U21TableColumn<EntityTransactionLinkTableRow>>
            >,
          ),
        ];
      }
      return [];
    }, [
      baseObjectType,
      data,
      entityTableConfig,
      history,
      networkGroups,
      pathname,
      search,
    ]);

  const instrumentTableConfig = useSelector(selectInstrumentTableConfig);
  const instrumentColumns: U21TableColumn<InstrumentTransactionLinkTableRow>[] =
    useMemo(() => {
      if (baseObjectType === BaseObjectType.INSTRUMENT && data) {
        return [
          getAmountColumn<InstrumentTransactionLinkTableRow>(),
          getLinksColumn<InstrumentTransactionLinkTableRow>(
            data?.graph_result?.nodes,
            networkGroups,
            history,
            pathname,
            search,
          ),
          ...createTableColumnConfig<InstrumentTransactionLinkTableRow>(
            instrumentTableConfig,
            INSTRUMENT_COLUMN_CONFIG as Record<
              string,
              Partial<U21TableColumn<InstrumentTransactionLinkTableRow>>
            >,
          ),
        ];
      }
      return [];
    }, [
      baseObjectType,
      data,
      instrumentTableConfig,
      history,
      networkGroups,
      pathname,
      search,
    ]);

  const table = useMemo(() => {
    if (baseObjectType === BaseObjectType.ENTITY) {
      return (
        <U21Table<EntityTransactionLinkTableRow>
          data={entityTableData}
          columns={entityColumns}
          onRowClick={(_, { external_id: extId }) =>
            reduxDispatch(
              toggleSidebar({
                type: SidebarComponentTypes.ENTITY,
                data: {
                  id: 0,
                  externalId: extId,
                },
              }),
            )
          }
          getRowID={({ rowId }: EntityTransactionLinkTableRow) => rowId}
        />
      );
    }
    if (baseObjectType === BaseObjectType.INSTRUMENT) {
      return (
        <U21Table<InstrumentTransactionLinkTableRow>
          data={instrumentTableData}
          columns={instrumentColumns}
          getRowID={({ rowId }: InstrumentTransactionLinkTableRow) => rowId}
        />
      );
    }
    return null;
  }, [
    baseObjectType,
    entityTableData,
    entityColumns,
    reduxDispatch,
    instrumentTableData,
    instrumentColumns,
  ]);

  return (
    <div ref={sectionRef}>
      <U21Section
        title={
          <U21Badge content={linkedTransactionsLength}>
            <span>Transactions</span>
          </U21Badge>
        }
        collapsible
        collapsed={!expandedSections.has(TRANSACTIONS_SECTION_HASH)}
        onToggleCollapse={getToggleExpandedSection(TRANSACTIONS_SECTION_HASH)}
        action={
          <LinkSectionActions
            tableConfigType={
              baseObjectType === BaseObjectType.ENTITY
                ? TableConfigType.ENTITY_TABLE
                : TableConfigType.INSTRUMENT_TABLE
            }
            hash={TRANSACTIONS_SECTION_HASH}
          />
        }
      >
        {table}
      </U21Section>
    </div>
  );
};

const getAmountColumn = <
  T extends EntityTransactionLinkTableRow | InstrumentTransactionLinkTableRow,
>(): U21TableColumn<T> => ({
  id: 'amount',
  Header: 'Amount',
  accessor: 'amount',
  cellProps: ({ amountColor }: T) => ({
    color: amountColor,
  }),
});

function makeTransactionsLinksTableData(
  fetchedObjects: NetworkAnalysisContextState['fetchedEntities'],
  networkGroups: NetworkAnalysisGroups,
  formatAmount: (payload: SelectFormatAmountPayload) => string,
): EntityTransactionLinkTableRow[];
function makeTransactionsLinksTableData(
  fetchedObjects: NetworkAnalysisContextState['fetchedInstruments'],
  networkGroups: NetworkAnalysisGroups,
  formatAmount: (payload: SelectFormatAmountPayload) => string,
): InstrumentTransactionLinkTableRow[];
function makeTransactionsLinksTableData<T>(
  fetchedObjects: Record<string, any>,
  { transactions, objects }: NetworkAnalysisGroups,
  formatAmount: (payload: SelectFormatAmountPayload) => string,
): T[] {
  return Object.values(transactions).reduce<T[]>((acc, txnEdge) => {
    const {
      id,
      source,
      target,
      transaction_data: { amount, currency, flow },
    } = txnEdge;
    const sourceObject = objects[source];
    const targetObject = objects[target];
    const formattedAmount = `${flow === 'OUTBOUND' ? '-' : ''}${formatAmount({
      amount,
      currencyCodeProps: currency ?? undefined,
    })}`;
    let amountColor =
      flow === 'OUTBOUND'
        ? palette.light.error.main
        : palette.light.success.main;
    if (flow === 'OTHER') {
      amountColor = palette.light.text.secondary;
    }
    const counterparty = sourceObject.is_base_node
      ? targetObject
      : sourceObject;
    const object = fetchedObjects[counterparty.external_id];
    acc.push({
      ...object,
      node_id: counterparty.id,
      hashed_id: counterparty.hashed_id,
      links: counterparty.links,
      amount: formattedAmount,
      rowId: id,
      amountColor,
    });
    return acc;
  }, []);
}
