// Components
import { U21Typography } from 'app/shared/u21-ui/components';
import { U21DynamicColorChip } from 'app/shared/u21-ui/components/dashboard';

// Types
import { ChainalysisContentField } from 'app/modules/alerts/types';
import {
  CHAINALYSIS_CHIP_KEYS,
  CHAINALYSIS_DATETIME_KEYS,
} from 'app/modules/alerts/components/AlertDetails/ChainalysisSummary/constants';
import { formatDatetime } from 'app/shared/utils/date';

export const chainalysisAlertDetailsCellRenderer = (
  key: ChainalysisContentField,
  value: any,
) => {
  if (CHAINALYSIS_CHIP_KEYS.includes(key)) {
    return <U21DynamicColorChip>{value.toUpperCase()}</U21DynamicColorChip>;
  }

  if (CHAINALYSIS_DATETIME_KEYS.includes(key)) {
    return (
      <U21Typography variant="body2">
        {formatDatetime(new Date(value))}
      </U21Typography>
    );
  }

  if (key === 'kytAlertUrl') {
    return (
      <a href={value} target="_blank" rel="noreferrer">
        View on Chainalysis
      </a>
    );
  }

  return value;
};
