import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

import HEAP_EVENTS from 'app/shared/utils/heapEvents';

// Utils
import { getRouteName, ROUTES_MAP } from 'app/shared/utils/routes';
import {
  HeapEventProperties,
  heapTrack,
  setHeapEventProperties,
} from 'app/shared/utils/heap';
import { lazyRetry } from 'app/shared/utils/react';
import { selectSessionReplayEnabled } from 'app/shared/featureFlags/selectors';
import { sessionReplay } from 'app/shared/utils/sentry';

// Components
import { LazyErrorFallback } from 'app/routers/LazyErrorFallback';
import { Route, Switch, useLocation } from 'react-router-dom';
import ProtectedRoute from 'app/routers/ProtectedRoute';

// Module Components
import FourOhFour from 'app/shared/pages/FourOhFour';
import SomethingWentWrong from 'app/shared/pages/SomethingWentWrong';
import { NotificationsPage } from 'app/modules/notifications/pages/NotificationsPage';
import { Home } from 'app/shared/components/Home';

import Login from 'app/modules/session/components/Login';
import EmailTokenExchange from 'app/modules/session/components/EmailTokenExchange';

// Helpers
import { globalPackageUpdates } from 'app/routers/helpers';

// Actions
import { toggleTheme } from 'app/modules/theme/sliceTheme';
import { usePageTimeTracking } from 'app/routers/usePageTimeTracking';
import { FeatureFlag } from 'app/shared/featureFlags/models';
import { AiAdminDashboard } from 'app/modules/aiAdmin/AiAdminDashboard';
import {
  selectSideBySideAlertEnabled,
  selectSideBySideCaseEnabled,
} from 'app/shared/components/GaBanner/selector';

const AlertsRouter = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "alerts" */ 'app/modules/alerts/pages/AlertsRouter'
    ),
  { fallback: LazyErrorFallback },
);
const CasesRouter = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "cases" */ 'app/modules/cases/pages/CasesRouter'
    ),
  { fallback: LazyErrorFallback },
);
const ListsRouter = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "lists" */ 'app/modules/lists/pages/ListsRouter'
    ),
  { fallback: LazyErrorFallback },
);
const DetectionModelRouter = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "detection-models" */ 'app/modules/detectionModels/pages/DetectionModelsRouter'
    ),
  { fallback: LazyErrorFallback },
);
const FilingsRouter = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "filings" */ 'app/modules/filings/pages/FilingsRouter'
    ),
  { fallback: LazyErrorFallback },
);
const InsightsHome = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "dashboards" */ 'app/modules/fraudDashboard/DashboardHome'
    ),
  { fallback: LazyErrorFallback },
);
const DataExplorerRouter = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "data-explorer" */ 'app/modules/dataExplorer/pages/DataExplorerRouter'
    ),
  { fallback: LazyErrorFallback },
);
const DataManagementRouter = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "data-management" */ 'app/modules/dataManagement/pages/DataManagementRouter'
    ),
  { fallback: LazyErrorFallback },
);
const RiskRatingsRouter = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "risk-ratings" */ 'app/modules/riskRatings/pages/RiskRatingsRouter'
    ),
  { fallback: LazyErrorFallback },
);
const UserManagementRouter = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "user-management" */ 'app/modules/userManagement/pages/UserManagementRouter'
    ),
  { fallback: LazyErrorFallback },
);
const WorkflowsRouter = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "workflows" */ 'app/modules/workflows2/pages/WorkflowsRouter'
    ),
  { fallback: LazyErrorFallback },
);
const Reporting = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "reporting" */ 'app/modules/reporting/pages/Reporting'
    ),
  { fallback: LazyErrorFallback },
);
const OrgManagementRouter = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "org-management" */ 'app/modules/orgManagement/pages/OrgManagementRouter'
    ),
  { fallback: LazyErrorFallback },
);
const RulesAdminDashboard = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "rules-admin" */ 'app/modules/rulesAdmin/pages/RulesAdminDashboard'
    ),
  { fallback: LazyErrorFallback },
);

export const AppRouter = () => {
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const sessionReplayEnabled = useSelector(selectSessionReplayEnabled);
  useEffect(() => {
    if (sessionReplayEnabled) {
      Sentry.addIntegration(sessionReplay);
    }
  }, [sessionReplayEnabled]);

  useEffect(() => {
    globalPackageUpdates();
  }, []);

  useEffect(() => {
    const name = getRouteName(pathname);
    document.title = name ? `Unit21 - ${name}` : 'Unit21';
  }, [pathname]);

  useEffect(() => {
    const onBlur = () => {
      heapTrack(HEAP_EVENTS.visibility.blur);
    };
    const onFocus = () => {
      heapTrack(HEAP_EVENTS.visibility.focus);
    };

    window.addEventListener('blur', onBlur);
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  usePageTimeTracking();

  const sideBySideAlertEnabled = useSelector(selectSideBySideAlertEnabled);

  useEffect(() => {
    setHeapEventProperties(
      HeapEventProperties.SIDE_BY_SIDE_ALERT_ENABLED,
      sideBySideAlertEnabled,
    );
  }, [sideBySideAlertEnabled]);

  const sideBySideCaseEnabled = useSelector(selectSideBySideCaseEnabled);

  useEffect(() => {
    setHeapEventProperties(
      HeapEventProperties.SIDE_BY_SIDE_CASE_ENABLED,
      sideBySideCaseEnabled,
    );
  }, [sideBySideCaseEnabled]);

  const keyCodeCounterRef = useRef(0);
  useEffect(() => {
    const KONAMI = [
      'ArrowUp',
      'ArrowUp',
      'ArrowDown',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ArrowLeft',
      'ArrowRight',
      'b',
      'a',
    ];
    const onKeyPress = (e) => {
      if (e.key === KONAMI[keyCodeCounterRef.current]) {
        keyCodeCounterRef.current += 1;
        if (keyCodeCounterRef.current === KONAMI.length) {
          dispatch(toggleTheme());
          keyCodeCounterRef.current = 0;
        }
      } else {
        keyCodeCounterRef.current = 0;
      }
    };
    window.addEventListener('keyup', onKeyPress);
    return () => window.removeEventListener('keyup', onKeyPress);
  }, [dispatch]);

  return (
    <Switch>
      <Route
        component={Home}
        exact={ROUTES_MAP.home.exact}
        path={ROUTES_MAP.home.path}
      />
      <Route
        component={Login}
        exact={ROUTES_MAP.agentLogin.exact}
        path={ROUTES_MAP.agentLogin.path}
      />
      <Route
        component={EmailTokenExchange}
        exact={ROUTES_MAP.emailLogin.exact}
        path={ROUTES_MAP.emailLogin.path}
      />

      {/* Alerts */}
      <Route
        path={['/alerts', ROUTES_MAP.alertPDF.path]}
        component={AlertsRouter}
      />

      {/* Cases */}
      <Route
        path={['/cases', ROUTES_MAP.pdfExportCasesId.path]}
        component={CasesRouter}
      />

      {/* Lists */}
      <Route component={ListsRouter} path={ROUTES_MAP.lists.path} />

      {/* Detection Models */}
      <Route
        component={DetectionModelRouter}
        path={[
          ROUTES_MAP.detectionModels.path,
          ROUTES_MAP.detectionModelPDF.path,
          ROUTES_MAP.detectionModelsIdTab.path,
        ]}
      />

      {/* Filings */}
      <Route component={FilingsRouter} path="/filings" />

      {/* Insights */}
      <ProtectedRoute
        component={InsightsHome}
        // add ? to make `:id` optional
        path={`${ROUTES_MAP.dashboardsId.path}?`}
        permissions={ROUTES_MAP.dashboardsId.permissions}
      />

      {/* Data Explorer */}
      <Route
        component={DataExplorerRouter}
        path={[
          ROUTES_MAP.dataExplorer.path,
          ROUTES_MAP.entitiesId.path,
          ROUTES_MAP.instrumentsId.path,
        ]}
      />

      {/* Data Management */}
      <Route
        component={DataManagementRouter}
        path={[
          ROUTES_MAP.dataManagement.path,
          ROUTES_MAP.dataManagementFlatFileIngestion.path,
          ROUTES_MAP.dataManagementDataMapping.path,
        ]}
      />

      {/* Risk */}
      <ProtectedRoute
        component={RiskRatingsRouter}
        path={ROUTES_MAP.riskRatings.path}
      />

      {/* User Management */}
      <Route
        component={UserManagementRouter}
        path={[
          ROUTES_MAP.agentsId.path,
          ROUTES_MAP.teamsId.path,
          ROUTES_MAP.userManagement.path,
        ]}
      />

      {/* Workflows */}
      <Route
        component={WorkflowsRouter}
        path={[
          '/queues',
          ROUTES_MAP.workflows.path,
          ROUTES_MAP.investigationChecklistsId.path,
          ROUTES_MAP.webhooksId.path,
          ROUTES_MAP.qualityId.path,
        ]}
      />

      {/* Reporting */}
      <Route component={Reporting} path={ROUTES_MAP.reporting.path} />

      {/* Org Management */}
      <ProtectedRoute
        component={OrgManagementRouter}
        path={ROUTES_MAP.orgManagement.path}
        permissions={ROUTES_MAP.orgManagement.permissions}
      />

      {/* AI Admin */}
      <ProtectedRoute
        requiredFlags={FeatureFlag.AI_ADMIN_DASHBOARD}
        component={AiAdminDashboard}
        path={ROUTES_MAP.aiAdmin.path}
        permissions={ROUTES_MAP.aiAdmin.permissions}
      />

      {/* Rules Admin */}
      <ProtectedRoute
        component={RulesAdminDashboard}
        path={ROUTES_MAP.rulesAdmin.path}
        permissions={ROUTES_MAP.rulesAdmin.permissions}
      />

      {/* Notifications */}
      <Route
        component={NotificationsPage}
        exact={ROUTES_MAP.notifications.exact}
        path={ROUTES_MAP.notifications.path}
      />

      {/* 404 */}
      <Route
        component={SomethingWentWrong}
        exact={ROUTES_MAP.error.exact}
        path={ROUTES_MAP.error.path}
      />
      <Route component={FourOhFour} />
    </Switch>
  );
};
