import { GetEntitiesPayload } from 'app/modules/entitiesRefresh/requests';
import { GetEntitiesResponse } from 'app/modules/entitiesRefresh/responses';

import { ENTITY_QUERY_KEYS } from 'app/modules/entitiesRefresh/queries/keys';

import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectDLEnabledForEntityList } from 'app/shared/featureFlags/selectors';

export const useGetEntities = <T = GetEntitiesResponse>(
  payload: GetEntitiesPayload,
  options: {
    enabled?: boolean;
    select?: (data: GetEntitiesResponse) => T;
  } = {
    enabled: true,
    select: undefined,
  },
) => {
  const useNewEndpoint = useSelector(selectDLEnabledForEntityList);

  const endpoint = useNewEndpoint ? '/entities/cached/list' : '/entities/list';

  return useQuery({
    queryFn: () => post<GetEntitiesResponse>(endpoint, payload),
    queryKey: [...ENTITY_QUERY_KEYS.getEntities(payload), endpoint],
    enabled: payload.limit !== 0 && options.enabled,
    select: options.select,
  });
};
