// Components
import { AuditTrailTab } from 'app/modules/investigations/components/InvestigationTabs/AuditTrailTab';
import { FormTabCaseDetails } from 'app/modules/cases/tabs/FormTabCaseDetails';
import {
  FormTabTable,
  FormTabTableKeys,
} from 'app/modules/cases/tabs/FormTabTable';
import { InvestigationChecklist } from 'app/modules/investigations/components/InvestigationTabs/InvestigationChecklist';
import { InvestigationChecklistLabel } from 'app/modules/investigations/components/InvestigationTabs/InvestigationChecklistLabel';
import { Notes } from 'app/modules/investigations/components/InvestigationTabs/Notes';
import { CaseAuditTrail } from 'app/modules/cases/components/CaseAuditTrail';
import { NarrativesList } from 'app/modules/narratives/components/NarrativesList';
import { TxnAnalysisTab } from 'app/modules/txnAnalysis/components/TxnAnalysisTab';
import { CaseFraudLossTab } from 'app/modules/cases/tabs/CaseFraudLossTab';

// Constants
import { TxnAnalysisType } from 'app/modules/txnAnalysis/constants';

// Types
import {
  InvestigationTabs,
  InvestigationType,
} from 'app/modules/investigations/models';

// Schemas
import { FormTabAttachment } from 'app/modules/cases/tabs/FormTabAttachment';
import permissions from 'app/shared/utils/permissions';
import { CaseConsortiumHitsTab } from 'app/modules/cases/tabs/CaseConsortiumHitsTab';
import { NetworkAnalysisTab } from 'app/modules/cases/tabs/NetworkAnalysisTab';

interface Options {
  id: number;
  readOnly: boolean;
  hasChecklist: boolean;
  hasHistory: boolean;
  comprehensiveAuditTrailsEnabled: boolean;
  showFraudLossTab: boolean;
  sideBySideInvestigationsEnabled: boolean;
  consortiumEnabled: boolean;
}

export const generateCaseTabs = (options: Options): InvestigationTabs[] => {
  const {
    id,
    readOnly,
    hasChecklist,
    hasHistory,
    comprehensiveAuditTrailsEnabled,
    showFraudLossTab,
    sideBySideInvestigationsEnabled,
    consortiumEnabled,
  } = options;

  return [
    {
      label: 'Details',
      path: 'details',
      component: <FormTabCaseDetails readOnly={readOnly} />,
    },
    {
      label: 'Transaction Analysis',
      path: 'txn-analysis',
      component: <TxnAnalysisTab type={TxnAnalysisType.CASE} />,
      hidden: false,
    },
    {
      label: 'Network Analysis',
      path: 'network-analysis',
      component: <NetworkAnalysisTab caseId={id} />,
      permissions: [permissions.readNetworkAnalysis],
    },
    ...(showFraudLossTab
      ? [
          {
            label: 'Loss Summary',
            labelString: 'Fraud Loss Summary',
            path: 'loss-summary',
            component: <CaseFraudLossTab />,
          },
        ]
      : []),
    ...((hasChecklist || hasHistory) && !sideBySideInvestigationsEnabled
      ? [
          {
            label: <InvestigationChecklistLabel />,
            labelString: 'Investigation checklist',
            path: 'investigation-checklist',
            component: <InvestigationChecklist type={InvestigationType.CASE} />,
          },
        ]
      : []),
    ...(sideBySideInvestigationsEnabled
      ? []
      : [
          {
            label: 'Notes',
            path: 'notes',
            component: (
              <Notes disabled={readOnly} type={InvestigationType.CASE} />
            ),
          },
        ]),
    {
      label: 'Filings',
      path: 'filings',
      component: <FormTabTable keyType={FormTabTableKeys.SARS} />,
      permissions: [permissions.readSars],
    },
    {
      label: 'Alerts',
      path: 'alerts',
      component: <FormTabTable keyType={FormTabTableKeys.ALERTS} />,
      hidden: true,
    },
    {
      label: 'Entities',
      path: 'entities',
      component: <FormTabTable keyType={FormTabTableKeys.ENTITIES} />,
      hidden: true,
    },
    {
      label: 'Action Events',
      path: 'action-events',
      component: <FormTabTable keyType={FormTabTableKeys.ACTION_EVENTS} />,
      hidden: true,
    },
    ...(sideBySideInvestigationsEnabled
      ? []
      : [
          {
            label: 'Documents',
            path: 'documents',
            component: <FormTabAttachment />,
            hidden: true,
          },
          {
            label: 'Narratives',
            path: 'narratives',
            component: (
              <NarrativesList
                readOnly={readOnly}
                type={InvestigationType.CASE}
              />
            ),
            hidden: true,
          },
          {
            label: 'Audit Trail',
            path: 'audit-trail',
            component: comprehensiveAuditTrailsEnabled ? (
              <CaseAuditTrail assetId={id} />
            ) : (
              <AuditTrailTab type={InvestigationType.CASE} />
            ),
            hidden: true,
          },
        ]),
    ...(consortiumEnabled
      ? [
          {
            label: 'Consortium Hits',
            path: 'consortium-hits',
            component: <CaseConsortiumHitsTab />,
            hidden: true,
          },
        ]
      : []),
  ];
};
