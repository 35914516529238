import { Filter } from 'app/modules/filters/models';

import {
  AI_ADMIN_TRACKING_FILTER_KEYS,
  AI_ADMIN_TRACKING_FILTER_KEYS_VALUES,
} from 'app/modules/aiAdmin/filters';

export const createAiAdminTrackingFilterPayload = (
  filters: Filter[],
): { filters: Filter[] } => {
  return {
    filters: filters.reduce((acc: Filter[], i) => {
      if (AI_ADMIN_TRACKING_FILTER_KEYS_VALUES.has(i.key)) {
        switch (i.key) {
          case AI_ADMIN_TRACKING_FILTER_KEYS.ID:
          case AI_ADMIN_TRACKING_FILTER_KEYS.ORG_ID:
          case AI_ADMIN_TRACKING_FILTER_KEYS.AI_INVESTIGATIONS_RESULT_ID:
          case AI_ADMIN_TRACKING_FILTER_KEYS.TOKENS_USED:
          case AI_ADMIN_TRACKING_FILTER_KEYS.INPUT_TOKENS:
          case AI_ADMIN_TRACKING_FILTER_KEYS.OUTPUT_TOKENS:
          case AI_ADMIN_TRACKING_FILTER_KEYS.COST:
          case AI_ADMIN_TRACKING_FILTER_KEYS.LATENCY_MS:
          case AI_ADMIN_TRACKING_FILTER_KEYS.QUALITY_SCORE:
            if (Number.isInteger(Number(i.value))) {
              acc.push(i);
            }
            break;
          default:
            acc.push(i);
        }
      }
      return acc;
    }, []),
  };
};
